import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import { Button, Collapse } from 'antd';

import "./Course.css";

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#fff',
  borderRadius: 0,
  border: 0,
  borderBottom: '4px solid #111',
};

export default class Course extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const links = [
      {title: 'Why you need this course', href: 'why', duration: 500},
      {title: 'About blue ocean', href: 'about', duration: 1000},
      {title: 'Course overview', href: 'overview'},
      {title: 'Get Started', href: 'pricing', class: 'active'},
    ];

    const why = [
      <div>You face <b>intense competition</b></div>,
      <div>You want to know how to create an <b>uncontested market space</b></div>,
      <div>You want to stop worrying about the <b>shrinking margins</b></div>,
      <div>You want to shift your company towards <b>new growth and innovation</b></div>,
    ];

    const inside = [
      {img: '/images/pi/1.png', label: 'Streaming videos', desc: '10 explainer videos introducing and explaining blue ocean concepts and frameworks.'},
      {img: '/images/pi/2.png', label: 'Key learning points', desc: 'A clear and easy-to-understand summary of the main insights from each module.'},
      {img: '/images/pi/3.png', label: 'Case illustrations', desc: 'Examples of how blue ocean concepts have been successfully applied in the real world.'},
      {img: '/images/pi/4.png', label: 'Real-life business scenarios', desc: 'Test your understanding of blue ocean tools and frameworks by working through real-life business scenarios.'},
      {img: '/images/pi/5.png', label: 'Assessment quizzes', desc: 'A short assessment quiz follows after each blue ocean case to help you focus on and retain the salient learning points brought out in each case.'},
      {img: '/images/pi/6.png', label: 'Extra resources', desc: 'Use carefully selected additional resources to deepen your knowledge and help you put it in practice.'},
    ];

    const modules = [
      {title: 'Module 1', img: '/images/pi/shifting-persepctive.png', desc: 'First, we\'ll introduce you to blue ocean creativity, a systematic process of new market creation. You\'ll then take a short assessment to check whether you are swimming in a bloody red ocean of competition or are on the path to creating your blue ocean.'},
      {title: 'Module 2', img: '/images/pi/mod2.png', desc: 'Shift your mindset to a blue ocean perspective'},
      {title: 'Module 3', img: '/images/pi/mod3.png', desc: 'Visualize your strategic reality'},
      {title: 'Module 4', img: '/images/pi/mod4.png', desc: 'Discover hidden pain points in your industry and how to turn them into opportunities to grow your business'},
      {title: 'Module 5', img: '/images/pi/mod5.png', desc: 'Look beyond existing demand by focusing on noncustomers'},
      {title: 'Module 6 & 7', img: '/images/pi/mod6.png', desc: 'Reconstruct existing market boundaries through a systematic process'},
      {title: 'Module 8', img: '/images/pi/mod8.png', desc: 'Develop alternative blue ocean moves'},
      {title: 'Module 9', img: '/images/pi/mod9.png', desc: 'Communicate your blue ocean move'},
      {title: 'Module 10', img: '/images/pi/mod10.png', desc: 'Make your blue ocean move'},
    ];

    const faqs = [
      {question: 'Who exactly is this course for?', answer: "<p>The Blue Ocean Practical Introduction&trade; course is for anyone looking for creative solutions in an increasingly competitive world.</p><p>&nbsp;</p><p><strong>Our customers include entrepreneurs, small business owners, corporate executives, managers from both nonprofit organizations and corporations from various industries.</strong></p><p>&nbsp;</p><p>If you believe that growth and a new way of doing things are possible even under most challenging situations, then you're in the right place.</p>"},
      {question: "I’m busy running a business. How long does it take to complete the training?", answer: "<p>It depends on how fast you want to go through the modules and how much time you need to assimilate new knowledge. In theory, you could complete the course in a day. However, <strong>we recommend that you pace yourself over several days</strong>. The course builds on the knowledge gained in earlier modules, so it is important to take the time to assimilate new concepts and let them sink in.</p><p>Also, we highly recommend that you work through the additional resources, including the exercise templates and extra reading, which will take substantially more time. You may even want to return to the course and repeat modules at a later date. You can do this at any time in the one year that you can access the course.</p>"},
      {question: "How much time do I have to complete the course?", answer: "<p>You will have <strong>one year to access the course</strong> and all the related materials from the moment you purchase it.</p>"},
      {question: "Is the course endorsed by the authors?", answer: "<p>Yes, Chan Kim and Ren&eacute;e Mauborgne have endorsed this online course. <strong>No other blue ocean online course has the authors&rsquo; endorsement</strong>.</p>"},
      {question: "Is this course downloadable to my computer?", answer: "<p>No, <strong>the course can be taken online only</strong>. However, certain elements are available for download. For example, you can download the exercise templates, print them out and work on them offline.&nbsp;The videos, quizzes and case studies can only be accessed when you are logged into the course and during the period you have access to the course.</p>"},
      {question: "Is there a trainer accessible if I have questions during the course?", answer: "<p>As this is an online self-study course, <strong>the course is designed in such a way that it doesn&rsquo;t require a live trainer</strong>.&nbsp;However, if you need individual or team coaching, please get in touch with our team at <a href=\"mailto:hello@blueoceanstrategy.com\">hello@blueoceanstrategy.com</a></p>"},
      {question: "Can the course be accessed on a mobile device?", answer: "<p>Yes, you can access it on most devices. However, for an optimal learning experience, we recommend accessing the course on a desktop or laptop computer.</p>"},
      {question: "Do I need any preparation to start the course?", answer: "<p>No. As this is an introductory course, <strong>you don&rsquo;t need prior knowledge</strong> of blue ocean strategy and blue ocean shift concepts, frameworks and methodologies. While we always recommend reading Blue Ocean Strategy and Blue Ocean Shift books for a more in-depth understanding of the concepts, reading the books is not necessary for taking the course.</p>"},
      {question: "I’ve read the books. Do I still need the course?", answer: "<p><strong>You will find the course useful whether or not you have read the books.</strong> If you have read the books already, the course will add to your practical knowledge of blue ocean concepts. If you haven&rsquo;t read the books, the course will serve as an ideal introduction to blue ocean concepts, which you can build on by reading the book chapters referenced in the course, although this is not essential. The quizzes and downloadable exercise templates will help you check your blue ocean knowledge and implement what you&rsquo;ve learned in the books or on the course.</p><p>&nbsp;</p><p>The course also comes with <strong>a certificate of completion</strong>, which you can add to your resume or LinkedIn profile to highlight your new blue ocean skills and knowledge.</p>"},
      {question: "Can I stop and resume the course where I left off?", answer: "<p><strong>Yes! You can go at your own pace.</strong> You can stop the course at any time and pick it up again where you left off without losing the progress you&rsquo;ve made. In fact, we recommend pacing yourself through the course over several days to assimilate new concepts, especially if you are new to blue ocean. We also recommend this route as each new module builds on what you learned in previous modules.</p>"},
      {question: "If I register today, how fast can I get the access?", answer: "<p>You will get instant access the minute you purchase the course. Your unique login information will be emailed to you automatically.</p>"},
      {question: "I’m well versed with the theory. Does this course offer practical knowledge?", answer: "<p>The course introduces the theory in the explainer videos and summarizes it in the key learning points. The remaining part of the module focuses on applying the knowledge in practice through real-life scenarios and quizzes that check your understanding. As such, <strong>you&rsquo;ll be able to test your knowledge of blue ocean in a practical, not theoretical, way.</strong> You&rsquo;ll learn how to identify noncustomers, how to flip pain points into opportunities, or how to use Kim &amp; Mauborgne&rsquo;s Six Paths Framework to create blue oceans. Each tool has its own exercises.</p>"},
      {question: "What are the payment options?", answer: "<p>You can purchase the course online with <strong>any of the major credit or debit cards</strong>. We use Stripe for processing payments. You can learn more about Stripe <a href=\"https://stripe.com/\">here</a>.</p><p>It is a one-time payment &ndash; no recurring or renewal payments will follow.</p>"},
      {question: "I want to buy the course for my team. What are my payment options?", answer: "<p>We understand that it may not be possible for employees to purchase the course individually with their personal credit cards. We&rsquo;re happy to help simplify the process on your end. <strong>Based on the number of your team members, we&rsquo;ll issue your company an invoice.</strong> Once the invoice is paid, we&rsquo;ll enroll your team members into the course. Please contact us at <a href=\"mailto:hello@blueoceanstrategy.com\">hello@blueoceanstrategy.com</a> to discuss your needs.</p>"},
      {question: "Can companies that purchase courses for teams track employees’ completion rate?", answer: "<p>Yes, for companies that enroll <strong>a minimum of 20 employees</strong>, we&rsquo;ll be able to provide you with the statistics on employees&rsquo; progress and completion rates.</p>"},
      {question: "What do I do if I have questions or need support?", answer: "<p>No problem. Just shoot us an email at <a href=\"mailto:hello@blueoceanstrategy.com\">hello@blueoceanstrategy.com</a> and our customer support team will get in touch.</p>"},
      {question: "Do I need any tech skills to use the course effectively?", answer: "<p>No, <strong>the course doesn&rsquo;t require any specialized tech skills.</strong> Simple knowledge of how to use a computer is sufficient.</p>"}
    ];

    return (<div id="pi">
      <section>
        <div className="container">
          <h3 className="text-center py-5 text-xxxlg font-weight-bold col-md-7 m-auto" style={{color: '#000'}}>Learn the Essentials of New Market Creation</h3>
          <div className="row py-md-5">
            <div className="col-md-6">
              <h6 className="my-3 font-weight-bold" style={{fontSize: 26}}>Blue Ocean Practical Introduction™ is your indispensable online course to learn:</h6>
              <ul className="check-list" style={{listStyle: 'none'}}>
                <li className="py-3">the fundamentals of blue ocean strategy and shift</li>
                <li className="py-3">how to create uncontested market space</li>
                <li className="py-3">what it takes to generate new growth and innovation</li>
              </ul>
            </div>
            <Fade right delay={800}>
              <div className="col-md-6">
                <img src="/images/pi/BOPI-laptop.png" className="px-4" />
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="bg-light text-center py-2">
        <div className="container">
          <nav class="nav justify-content-center">
            { links.map(link => (
              <div className="nav-item">
                <Link className={`nav-link ${link.class}`} to={link.href} smooth={true} duration={link.duration || 1500} offset={-68}>{link.title}</Link>
              </div>
            ))}
          </nav>
        </div>
      </section>
      <section id="why" className="bg-primary py-md-5">
        <div className="container py-5 text-center">
          <h3 className="text-white text-xlg font-weight-bold mb-5 mx-auto col-md-10">Why you need this course</h3>
          <div className="text-center">
            <Fade up cascade duration={2000}>
              {why.map(item => (
                <h6 className="text-white mb-5" style={{fontSize:22}}>{item}</h6>
              ))}
            </Fade>
          </div>
        </div>
      </section>
      <section className="py-4">
        <div className="container pt-5">
          <h5 className="text-center mb-5 mx-auto col-md-10">You're not alone</h5>
          <div class="row mt-3">
            <div class="offset-lg-1 col-lg-6 l-h">
              <p>What industry today doesn’t face intense competition?</p>
              <p>&nbsp;</p>
              <p>Companies have always fought to get a competitive advantage over each other in terms of profitable growth, market share, and product differentiation.</p>
              <p>&nbsp;</p>
              <p>In the current business environment, <b>it is more difficult than ever to stand out</b>. If you want your business to grow and scale, you need to move away from competing.</p>
              <p>&nbsp;</p>
              <p>Instead, you should move into – and create – new markets.</p>
              <p>&nbsp;</p>
              <p>Successful companies are not competing in red oceans. They are creating blue oceans – wide-open new markets.</p>
            </div>
            <Fade right>
              <div className="col-lg-4">
                <img class="img-fluid" src="/images/pi/red-oceans.png" />
              </div>
            </Fade>
            <div className="mx-auto col-lg-3 col-md-6 col-8 bg-primary" style={{height:4,marginTop:84}}></div>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="container pt-5">
          <h5 className="text-center mb-5 mx-auto col-md-10">Chan Kim & Renée Mauborgne<br/>#1 business thinkers in the world</h5>
          <div class="row">
            <Fade>
              <div className="col-lg-6 p-0">
                <img src="/images/pi/Profs.png" className="authors" style={{bottom: 0}} />
              </div>
            </Fade>
            <div class="col-lg-6 mt-md-5 py-4 mb-3">
              <p>Chan Kim and Renée Mauborgne are Professors of Strategy at INSEAD, one of the world’s top business schools and co-directors of the INSEAD Blue Ocean Strategy Institute.</p>
              <p>&nbsp;</p>
              <p>In 2019, they were named the <a target="_blank" href="https://thinkers50.com/media/creators-of-blue-ocean-strategy-top-thinkers50-ranking-new-generation-of-management-gurus-announced/">world’s most influential business thinkers by Thinkers50.</a></p>
              <p>&nbsp;</p>
              <p>Chan Kim and Renée Mauborgne spent over 30 years researching how can companies break out of this red ocean of bloody competition and generate strong, profitable growth. They uncovered what it takes to create new market space and make the competition irrelevant.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light pt-5 py-4">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-5 mt-5">
              <p>Their initial results culminated in their first book, <a target="_blank" href="https://www.amazon.com/gp/product/1625274491/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1625274491&linkCode=as2&tag=blueoceanstra-20&linkId=6781f365d5d97daa5fca9f9ca5b938b5">Blue Ocean Strategy</a>, first published in 2005 and expanded in 2015. The book has since sold over 4 million copies worldwide and has been translated into record-breaking 46 languages, becoming a bestseller on five continents.</p>
              <p>&nbsp;</p>
              <p>The follow-up, New York Times and #1 Wall Street Journal bestseller, <a target="_blank" href="https://www.amazon.com/gp/product/0316314048/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0316314048&linkCode=as2&tag=blueoceanstra-20&linkId=73b5561372e172c758760b5b954332ec">Blue Ocean Shift</a>, shows a step-by-step process for creating new markets and seizing new growth.</p>
            </div>
            <Fade right cascade duration={window.innerWidth <= 760 ? 0 : 1000}>
              <div className="col-lg-5 col-md-8 mx-auto py-5">
                <img className="img-fluid ml-4" src="/images/pi/BOSH_pages_3D.png" width={150} />
                <img className="img-fluid" src="/images/pi/BOS_pages_3D.png" width={231} />
              </div>
            </Fade>
            <div className="col-lg-10 mx-auto">
              <img className="img-fluid" src="/images/pi/Bestsellers1.png" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container text-center py-4">
          <img src="/images/pi/quote.png" width={90} className="mb-5" />
          <h2 class="col-lg-7 font-weight-bold mx-auto mb-5">The best way to beat the competition is to stop trying to beat the competition</h2>
          <div className="mb-5 font-weight-bold">Chan Kim and Renée Mauborgne</div>
          <h3 className="text-lg font-weight-bold">Learn how</h3>
          <Link to="overview" smooth={true} duration={500} offset={-68}>
            <img className="mt-4 img-fluid" src="/images/pi/learnmore.png" width={66} />
          </Link>
        </div>
      </section>
      <section id="overview" className="bg-primary pos-rlt laptop-top">
        <div className="container py-5 text-center">
          <h5 className="text-white text-xlg mt-4">THE BLUE OCEAN PRACTICAL INTRODUCTION™</h5>
          <h6 className="text-white col-lg-6 col-md-8 mx-auto my-5">An online course designed to show you how to create your blue ocean, fast.</h6>
        </div>
        <div className="laptop">
          <Fade duration={window.innerWidth <= 760 ? 0 : 1000}>
            <img src="/images/pi/BOPI-laptop.png" width={500} />
          </Fade>
        </div>
      </section>
      <section className="bg-light laptop-bottom">
        <div className="container py-5">
          <h5 className="text-center">Key takeaways from the course</h5>
          <div class="row">
            <div class="offset-lg-1 col-lg-5 col-md-6 mt-md-5">
              <Fade up cascade duration={2500}>
                <ul className="check-list" style={{listStyle: 'none'}}>
                  <li className="mb-4"><p>A clear understanding of the blue ocean concepts and the key blue ocean tools and frameworks, and how to apply them.</p></li>
                  <li className="mb-4"><p>The essential skills to use and interpret each tool and framework and gain blue ocean insights by shifting your perspective.</p></li>
                  <li className="mb-4"><p>An increased level of creative thinking for solving problems, shifting perspectives and seizing new opportunities.</p></li>
                </ul>
              </Fade>
            </div>
            <Fade right>
              <div className="col-lg-5 col-md-6">
                <img class="img-fluid" src="/images/pi/shifting-persepctive.png" />
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="py-4">
        <div className="container py-5 text-center">
          <h5 className="mb-5 mx-auto col-lg-10">What's inside</h5>
          <div className="mb-5 mx-auto col-lg-7 col-md-10 p-md-0">
            <p>Blue Ocean Practical Introduction™ is made up of 10 interactive modules. Each module begins with a video outlining a key blue ocean concept and the related tool or framework.</p>
            <p>&nbsp;</p>
            <p>You’ll learn <b>how to draw the ‘as-is’ strategy canvas</b> of your business, <b>how to identify the noncustomers</b> to tap into new demand, <b>how to reconstruct market boundaries</b> and <b>unlock commercially compelling new business ideas</b>, and much more.</p>
            <p>&nbsp;</p>
            <p>Modules are arranged in sequence following the steps in the blue ocean process.</p>
          </div>
          <div className="row">
          <div className="col-lg-10 mx-auto row">
            { inside.map(item => (
              <Fade up>
                <div className="col-md-4 text-center mb-5">
                  <div style={{minHeight: 150}}>
                    <img className="img-fluid" style={{width: 150}} src={item.img} />
                  </div>
                  <div className="font-weight-bold mb-3" style={{fontSize:17}}>{item.label}</div>
                  <p>{item.desc}</p>
                </div>
              </Fade>
            ))}
          </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-4">
        <div className="container py-5">
          <h5 className="text-center mb-5">Curriculum</h5>
          <p className="col-lg-6 col-md-8 mx-auto font-weight-normal text-center">The Blue Ocean Practical Introduction™ online course consists of 10 modules that will introduce you to key concepts and tools of blue ocean strategy and blue ocean shift.</p>
          <div className="row">
            <div className="mx-auto col-lg-7 col-md-10 mt-4 py-4">
              { modules.map(mod => (
                <div class="row my-5">
                  <Fade>
                    <div className="col-md-4 text-center order-md-1 order-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="cicle bg-white" style={{
                        width: 160,
                        height: 160,
                        borderRadius: '100%',
                        backgroundImage: `url(${mod.img})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                      }}></div>
                    </div>
                  </Fade>
                  <div class="offset-md-1 col-md-7 order-md-2 order-1" 
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center'
                    }}
                  >
                    <Fade>
                      <div>
                        <h6 className="mb-2" style={{fontSize: 22, fontWeight: 'bold'}}>{mod.title}</h6>
                        <p className="mb-4">{mod.desc}</p>
                      </div>
                    </Fade>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary py-4">
        <div className="container py-5 text-center">
          <h5 className="mb-md-5 mx-auto col-md-10 text-white">Look inside Module 4</h5>
          <iframe style={{maxWidth:'100%'}} width="780" height={window.innerWidth <= 760 ? 250 : 450} src="https://player.vimeo.com/video/377514664?autoplay=1&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; accelerometer; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </section>
      <section className="py-4">
        <div className="container py-5">
          <h5 className="mb-5 text-center">Bonus resources</h5>
          <div className="row">
            <div className="offset-lg-1 col-lg-5 col-md-6 my-md-5 px-md-5"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center'
              }}
            >
              <h6 class="mb-3 font-weight-bold">Blue ocean Q&A</h6>
              <p>Especially for this course, authors Chan Kim and Renée Mauborgne have answered some of the most frequently asked questions they receive on Blue Ocean Strategy and Blue Ocean Shift.</p>
            </div>
            <Fade right>
              <div className="offset-lg-1 col-lg-4 col-md-6">
                <img class="img-fluid shadow" src="/images/pi/qanda.png" />
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="bg-light py-4">
        <div className="container py-5">
          <div className="row">
            <Fade left>
              <div className="offset-lg-1 col-lg-5 col-md-6 mb-5">
                <img className="img-fluid" src="/images/pi/templates.png" />
              </div>
            </Fade>
            <div className="col-lg-5 col-md-6 my-5 px-md-5"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center'
              }}
            >
              <h6 className="mb-3 font-weight-bold">Exercise templates</h6>
              <p>You'll also find downloadable templates you can print and use with your team to start developing your next blue ocean.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary py-5">
        <div className="container text-center py-5">
          <h5 className="text-white text-xlg">Certificate of completion</h5>
          <div className="row">
            <div className="col-lg-6 col-md-8 my-5 mx-auto">
              <img className="img-fluid" src="/images/pi/Certificate-sample.png" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <p className="text-white">Once you have completed the ten modules of the course, you will receive a Certificate of Completion from Blue Ocean Academy, the official blue ocean learning provider under the Blue Ocean Global Network, founded by Professors Chan Kim and Renée Mauborgne. Blue Ocean Global Network is a global community of practice on the Blue Ocean concepts and it embraces academics, consultants, executives, and government officials.</p>
              <p>&nbsp;</p>
              <p className="text-white"><strong>Add the certificate to your LinkedIn profile and resume to highlight the new skills and learning you continue to develop that allow you to stand apart in the marketplace.</strong></p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-4">
        <div className="container py-5">
          <h5 className="text-dark text-center text-lg mb-4">Frequently Asked Questions</h5>
          <div className="col-lg-9 mx-auto">
            <Collapse
              bordered={false}
            >
            { faqs.map((faq, index) => (
              <Panel header={<h6 className="bg-primary py-4 px-3 text-white font-weight-bold text-md mb-0">{faq.question}</h6>} key={index} showArrow={false} style={customPanelStyle}>
                <p className="bg-white p-3" dangerouslySetInnerHTML={{__html: faq.answer}} />
              </Panel>
            ))}
            </Collapse>
          </div>
        </div>
      </section>
      <section id="pricing" className="py-md-5 py-3">
        <div className="container text-center">
          <div className="row">
            <div className="mx-auto col-lg-9 py-5 bg-light">
              <h5 className="text-lg col-md-10 mx-auto mt-3 mb-5">Learn blue ocean tools and frameworks and create new market space.</h5>
              <h2 className="font-weight-bold" style={{fontSize:46}}>
                <span style={{textDecoration: 'line-through'}}>$247</span>
                <span style={{marginLeft: '14px'}}>$187 USD</span>
              </h2>
              <p className="font-weight-semibold" style={{color: '#f00'}}>Available for limited time only</p>
              <p>One-time payment</p>
              <div className="col-md-6 mx-auto my-4">
                <Button size="large" type="primary" block={true} href="/payment?pid=blue-ocean-practical-introduction">GET STARTED</Button>
              </div>
              <img width={120} className="mt-2" src="/images/cc-icon.png" />
              <div className="col-md-9 mx-auto my-4" style={{borderTop:'2px solid',height:2}}></div>
              <p>Connect with us</p>
              <img width={150} src="/images/pi/social-icons.png" />
            </div>
          </div>
        </div>
      </section>
    </div>);
  }
}