import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import Prismic from 'prismic-javascript';
import PrismicReact from 'prismic-reactjs';
import { Select, Skeleton } from 'antd';

const { Option } = Select;

const ProductSlat = ({product}) => {
  const image = product.data.card_image;
  const slat = (
    <div className="bg-white row m-0 mb-3 slat" style={{
      minHeight: '160px'
    }}>
      <div className="col-sm-3" style={{
        backgroundImage: `url(${image && image.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}></div>
      <div className="col-sm-9 p-3 px-4">
        <h5 className="mb-1 text-dark">
          {PrismicReact.RichText.asText(product.data.title)}
        </h5>
        <p>{PrismicReact.RichText.asText(product.data.subtitle)}</p>
        <p>{PrismicReact.RichText.asText(product.data.summary)}</p>
      </div>
    </div>
  )
  return product.uid === 'blue-ocean-practical-introduction' ?
    <a href="https://learn.blueoceanstrategy.com/blue-ocean-strategy-online-course">{slat}</a> : 
    <Link to={`/product/${product.uid}`}>{slat}</Link>
};

export default class Training extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getSingle('training').then((doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  fetchProducts(props, format) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        format ? Prismic.Predicates.any('my.product.format', [format]) : Prismic.Predicates.any('my.product.type', ['training']),
        { orderings : '[my.product.weight]' }
      ).then(response => {
        let products = {};
        response.results.forEach(item => {
          if (item.data.format) {
            if (!products[item.data.format]) {
              products[item.data.format] = [];
            }
            products[item.data.format].push(item);
          }
        });
        this.setState({ products });
      });
    }
    return null;
  }

  handleChange = (selected) => {
    console.log(selected);
    this.fetchProducts(this.props, selected);
  }

  render() {
    const { prismicCtx } = this.props;
    const { doc, products } = this.state;

    if (!doc) {
      return <div className="container py-5"><Skeleton /></div>
    }

    return (
      <div id="training">
        <Helmet>
          <title>Blue Ocean Academy™</title>
          <meta name="description" content="The Official Blue Ocean Online Learning, Training, Coaching, Workshop, Webinar, Seminar, Executive Roundtable, Consulting & Leadership Transformation"></meta>
        </Helmet>
        <section className="video-bg-container">
          <div className="video-bg-overlay"></div>  
          <video className="video-bg" autoPlay muted loop>
            <source src="/images/Training_bg.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="container py-5 video-bg-title">
            <h2 className="font-weight-normal text-primary">{PrismicReact.RichText.asText(doc.data.title)}</h2>
            <div className="col-md-5 p-0">{PrismicReact.RichText.render(doc.data.overview, prismicCtx.linkResolver)}</div>
          </div>
        </section>
        <section className="bg-primary" style={{minHeight:'4px'}}></section>
        <section className="bg-light py-5">
          <div class="col-md-9 mx-auto">
            <div className="text-right mb-4">
              <Select defaultValue="" style={{ width: 200 }} onChange={this.handleChange}>
                <Option value="">All</Option>
                <Option value="Online Learning">Online Learning</Option>
                <Option value="On-Site Workshop / Seminar">On-Site Workshop / Seminar</Option>
                <Option value="Webinar Series">Webinar Series</Option>
              </Select>
            </div>
            { products && Object.keys(products).map(type => {
              return (
                <div key={type} className="mb-5">
                  <h5 class="text-capitalize text-dark mb-3">{type}</h5>
                  { products[type].map(product => {
                    return (<ProductSlat product={product} />)
                  })}
                </div>
              )
            })}
          </div>
        </section>
        <section className="bg-primary py-5">
          <div className="container text-center py-4">
            <h3 className="text-white mb-4">Explore the Blue Ocean Studio™ creative workspace!</h3>
            <a href="https://learn.blueoceanstrategy.com/blue-ocean-studio" className="btn btn-outline-primary">Learn more</a>
          </div>
        </section>
      </div>
    );
  }
}