import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import { Carousel, Skeleton } from 'antd';

import './Studio.css';

export default class Studio extends React.Component {
  constructor(props){
    super(props);
    document.title = 'Blue Ocean Studio™ | Blue Ocean Academy';
    this.state = {
      doc: null,
      notFound: false,
      toolIndex: 0,
      moduleIndex: 0
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentWillReceiveProps(nextProps){
    if (this.props.prismicCtx !== nextProps.prismicCtx) {
      this.fetchPage(nextProps);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getSingle('studio').then((doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      return (
        <div className="studio-page">
          <Helmet>
            <title>Blue Ocean Studio™ | Blue Ocean Academy</title>
            <meta name="description" content="Blue Ocean Studio™ is an intuitive online learning and application workspace that allows you to effectively learn and apply the blue ocean concepts, and collaborate with your team as you formulate your move for creating new market space."></meta>
          </Helmet>
          <section className="video-bg-container">
            <div className="video-bg-overlay"></div>  
            <video className="video-bg" autoPlay muted loop>
              <source src="/images/Studio-Website.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <div className="container py-5 video-bg-title">
              <h2 className="font-weight-normal text-primary">{PrismicReact.RichText.asText(this.state.doc.data.title)}</h2>
              <p className="lead col-md-5 p-0">{PrismicReact.RichText.asText(this.state.doc.data.tagline)}</p>
            </div>
          </section>
          <section className="bg-primary" style={{minHeight:'4px'}}></section>
          <section id="about" className="bg-offset-left">
            <div className="container pt-5 pos-rlt">
              <div className="row mt-5">
                <div className="col-lg-8 mb-5 mb-lg-0 mx-auto text-center">
                  {PrismicReact.RichText.render(this.state.doc.data.what_is_blue_ocean, this.props.prismicCtx.linkResolver)}
                  <div className="mt-5 col-lg-7 col-md-5 px-0 mx-auto">
                    <Link className="btn btn-primary btn-block" to="#pricing" smooth>Subscribe Now</Link>
                  </div>
                </div>
                {/* <div className="col-lg-7 text-center">
                  <iframe src="https://player.vimeo.com/video/317879188" title="Blue Ocean Studio™ Promo" style={{height:'410px'}} className="animated fadeIn px-0 w-100 shadow-lg bg-white" frameBorder="0" allowFullScreen></iframe>
                </div> */}
              </div>
            </div>
          </section>
          <section className="container mt-5 pt-5">
            <div className="text-center pb-4 col-md-8 m-auto">
              {PrismicReact.RichText.render(this.state.doc.data.stats_copy, this.props.prismicCtx.linkResolver)}
            </div>
            <div className="row text-center">
              <div className="col-md-4">
                <object data="/images/stats-users.svg" type="image/svg+xml"></object>
                <h3 className="mt-3 font-weight-light">260,000+</h3><h6 className="font-weight-light">users trained online</h6>
              </div>
              <div className="col-md-4">
                <object data="/images/stats-projects.svg" type="image/svg+xml"></object>
                <h3 className="mt-3 font-weight-light">20,000+</h3><h6 className="font-weight-light">projects created within the Studio</h6>
              </div>
              <div className="col-md-4">
                <object data="/images/stats-organizations.svg" type="image/svg+xml"></object>
                <h3 className="mt-3 font-weight-light">280+</h3><h6 className="font-weight-light">organizations across the globe</h6>
              </div>
            </div>
          </section>
          <section className="bg-white py-lg-5 bg-offset-right">
            <div className="container text-center py-5 pos-rlt">
              <div className="text-center pb-4 col-md-8 m-auto">
                {PrismicReact.RichText.render(this.state.doc.data.overview_copy, this.props.prismicCtx.linkResolver)}
              </div>
              <div className="pos-rlt">
                <div className="col-lg-7 m-auto"><img className="img-fluid animated fadeIn d-none d-sm-none d-md-none d-lg-block shadow-lg" src="/images/dashboard.png" alt="Blue Ocean Studio™ Dashboard" /></div>
                <div className="text-left col-lg-3 note-1 animated fadeInUp mb-4">
                  <h6 className="text-primary">Unlimited projects &amp; portfolios</h6>
                  <div className="font-weight-light">Create as many projects and portfolios as you would like</div>
                </div>
                <div className="text-left col-lg-3 note-2 animated fadeInUp mb-4">
                  <h6 className="text-primary">Step-by-step guidance</h6>
                  <div className="font-weight-light">Follow the 5 steps to making a blue ocean shift successfully</div>
                </div>
                <div className="text-left col-lg-3 note-4 animated fadeInUp mb-4">
                  <h6 className="text-primary">Unlimited collaborators</h6>
                  <div className="font-weight-light">Invite your whole team to work on your project</div>
                </div>
                <div className="text-left col-lg-3 note-3 animated fadeInUp mb-4">
                  <h6 className="text-primary">Blue ocean templates</h6>
                  <div className="font-weight-light">View sample templates that you can use as a starting point</div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-light text-secondary bg-offset-left">
            <div className="container pb-5">
              <div className="text-center pb-4 col-md-8 m-auto">
              {PrismicReact.RichText.render(this.state.doc.data.tools_copy, this.props.prismicCtx.linkResolver)}
              </div>
              <div className="row">
                <div className="col-md-7 order-md-2 mb-4">
                  <Carousel ref={slider => (this.tools = slider)} beforeChange={(from, to) => this.setState({toolIndex: to})} autoplay effect="fade" className="shadow-lg">
                    {
                      this.state.doc.data.tools.map((item, index) => {
                        return (<div key={index}><img className="img-fluid" src={item.tool_image.url} alt={PrismicReact.RichText.asText(item.tool_name)} /></div>)
                      })
                    }
                  </Carousel>
                </div>
                <div className="offset-md-1 col-md-3 order-md-1 px-0">
                  <ul className="font-weight-light tool-list list-group">
                    {
                      this.state.doc.data.tools.map((item, index) => {
                        return (
                          <li key={index}>
                            { this.state.toolIndex === index && <button className="btn text-primary px-0">{PrismicReact.RichText.asText(item.tool_name)}</button>}
                            { this.state.toolIndex !== index && <button className="btn px-0" onClick={e => this.tools.goTo(index, true)}>{PrismicReact.RichText.asText(item.tool_name)}</button>}
                          </li>)
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white text-secondary">
            <div className="container py-5">
              <div className="text-center pb-4 col-md-8 m-auto">
                {PrismicReact.RichText.render(this.state.doc.data.collaboration_copy, this.props.prismicCtx.linkResolver)}
              </div>
              <div className="row text-center collaboration">
                {
                  this.state.doc.data.collaboration.map((item, index) => {
                    return (
                      <div className="col-md-3" key={index}>
                        <div className="circle m-auto" style={{backgroundImage: `url(${item.step_image.url})`}}></div>
                        <h6 className="font-weight-semibold p-4 mt-3">{PrismicReact.RichText.asText(item.step_title)}</h6>
                        { index < this.state.doc.data.collaboration.length - 1 && (
                          <img className="arrow d-none d-sm-none d-md-none d-lg-block" src="/images/circle/arrow.png" alt="" />
                        )}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
          <section id="pricing" className="bg-primary text-secondary py-5">
            <div className="container">
              <div className="text-center pb-4 col-md-8 mx-auto text-white">
                {PrismicReact.RichText.render(this.state.doc.data.pricing_copy, this.props.prismicCtx.linkResolver)}
              </div>
              <div className="card-deck mb-3 col-lg-10 mx-auto text-center">
                {
                  this.state.doc.data.pricing.map((item, index) => {
                    return (
                      <div className="card mb-4 shadow" key={index} style={{minHeight: '520px', border: item.plan_banner.length > 0 ? '3px solid #febc25': 0}}>
                        { item.plan_banner.length > 0 && (
                          <div className="bg-warning text-white py-2 font-weight-bold" style={{borderRadius: 0, fontSize: '16px', backgroundColor: '#ffbc24!important'}}>{PrismicReact.RichText.asText(item.plan_banner)}</div>
                        )}
                        { item.plan_banner.length === 0 && (
                          <div style={{height: 40}}></div>
                        )}
                        <div className="pt-3">
                          <h5 className="my-0 font-weight-semibold pt-2 text-secondary">{PrismicReact.RichText.asText(item.plan_title)}</h5>
                        </div>
                        <div className="card-body">
                          <h1 className="card-title pricing-card-title font-weight-bold text-secondary mb-0">{PrismicReact.RichText.asText(item.plan_price)}</h1>
                          <h6 className="text-muted" style={{height:'24px'}}>{PrismicReact.RichText.asText(item.plan_price_subtitle)}</h6>
                          <div className="text-left mt-3 mb-4 font-weight-semibold">
                            {PrismicReact.RichText.render(item.plan_factors, this.props.prismicCtx.linkResolver)}
                          </div>
                          <div className="p-4" style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
                            <a href={`/payment?pid=blue-ocean-studio&index=${PrismicReact.RichText.asText(item.plan_index)}`} className="btn btn-lg btn-block btn-primary mb-2">
                              { PrismicReact.RichText.asText(item.plan_price) === 'USD $0' ? "Sign Up" : "Subscribe" }
                            </a>
                            <div className="text-muted" style={{fontSize: '11px', lineHeight: '18px', minHeight: '36px'}}>{PrismicReact.RichText.asText(item.plan_disclaimer)}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <div className="container py-5"><Skeleton /></div>;
  }
}