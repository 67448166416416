import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import { withFirebase } from '../components/Firebase';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import Moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { Skeleton, Carousel, Card, Icon, notification } from 'antd';
import ContactModal from '../components/ContactModal';
import ProductCard from '../components/ProductCard';

import './Product.css';

const { Meta } = Card;

const EventCard = ({event}) => {
  return (
    <Card 
      hoverable
      cover={(
        <div style={{height: 160}}>
          <div className="bg" style={{height: 160}}>
            { event.event_image && <img src={event.event_image.url} alt={PrismicReact.RichText.asText(event.event_title)} /> }
            <div className="overlay">
              { event.status === 'Enquiry' && <ContactModal style={{transform: 'translateY(70px)'}}>Enquire Now</ContactModal>}
              { event.status === 'Open' && <button className="btn btn-outline-primary" style={{transform: 'translateY(70px)'}}>Register</button> }
              { event.status === 'Full' && <button className="btn btn-warning" disabled style={{transform: 'translateY(70px)'}}>Fully Booked!</button> }
              { event.status === 'Completed' && <button className="btn btn-warning" disabled style={{transform: 'translateY(70px)'}}>Completed!</button> }
            </div>
          </div>
        </div>
      )}
    >
      <Meta
        title={PrismicReact.RichText.asText(event.event_title)}
        description={PrismicReact.RichText.asText(event.event_description)}
      />
      { event.display_date !== 'false' && (
        <div className="mt-3">
          <div><strong>Dates:</strong> {Moment.utc(event.start_date).format('LL')} - {Moment.utc(event.end_date).format('LL')}</div>
        </div>
      )}
    </Card>
  )
}

const Section = (props) => {
  return (
    <section className={props.className}>
      <div className="container py-5">
        { props.children }
      </div>
    </section>
  );
}

// Declare your component
class Product extends React.Component {
  constructor(props){
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      doc: null,
      notFound: false,
      notifyVisible: false,
      email: '',
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentWillReceiveProps(nextProps){
    if (this.props.prismicCtx !== nextProps.prismicCtx ||
      nextProps.location.pathname !== this.props.location.pathname) {
      this.fetchPage(nextProps);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    this.setState({doc: null});

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('product', props.match.params.uid, 
        {'fetchLinks': 
          [
            'product.title', 
            'product.subtitle', 
            'product.card_image', 
            'product.summary', 
            'event.event_image',
            'event.event_title', 
            'event.event_description', 
            'event.venue', 
            'event.start_date',
            'event.end_date',
            'event.status',
            'event.display_date',
          ]
        }, (err, doc) => {
          if (doc) {
            // We put the retrieved content in the state as a doc variable
            this.setState({ doc });
            console.log(doc);
            console.log(PrismicReact.RichText.asText(doc.data.program_structure_title));
          } else {
            // We changed the state to display error not found if no matched doc
            this.setState({ notFound: !doc });
          }
        });
    }
    return null;
  }

  showNotifyModal = (event) => {
    this.props.firebase.enquiries().get().then((querySnapshot) => {
      console.log(querySnapshot);
    });

    this.setState({
      notifyVisible: true,
      selectedEvent: event
    });
  }

  handleOkNotifyModal = (e) => {
    e.preventDefault();
    this.props.firebase
      .enquiries()
      .add({
        email: this.state.email,
        event: `${PrismicReact.RichText.asText(this.state.doc.data.title)} - ${PrismicReact.RichText.asText(this.state.selectedEvent.event_title)}`,
        created: Date.now(),
      })
      .then(() => {
        this.setState({
          notifyVisible: false,
          email: ''
        });
        notification.open({
          message: 'Thank you for your interest!',
          description: 'You will be notified via email once this event has become available.',
          icon: <Icon type="check-circle" style={{ color: '#33b73f' }} />,
          placement: 'bottomLeft',
        });
      })
  }

  handleCloseNotifyModal = (e) => {
    this.setState({
      notifyVisible: false,
      email: '',
      selectedEvent: null
    });
  }

  handleRegister = (e) => {
    var url = `/payment?pid=${this.state.doc.uid}`;
    if (this.state.email) {
      url += `&email=${this.state.email}`;
    }
    this.props.history.push(url);
  }

  renderRegister = () => {
    const { doc } = this.state;
    console.log(doc);
    return (
      <div id="register" className="py-5 pos-rlt">
        <div className="container">
          <div className="row">
            { doc.data.pricing.length === 1 && (
              <form id="registerForm" action="/payment" onSubmit={this.handleRegister} className="offset-lg-3 col-lg-6">
                <h4 className="text-center text-primary">{PrismicReact.RichText.asText(doc.data.pricing[0].price_description)}</h4>
                <input type="hidden" value={doc.uid} />
                { doc.data.key_features && (
                  <div className="my-3 mb-5 text-center">
                    {PrismicReact.RichText.render(doc.data.key_features, this.props.prismicCtx.linkResolver)}
                  </div>
                )}
                <div className="input-group shadow">
                  <input type="text" className="form-control border-white" placeholder="Enter your email address to subscribe!" onChange={e => this.setState({email: e.target.value})} />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit" onClick={this.handleRegister}>Subscribe</button>
                  </div>
                </div>
                { PrismicReact.RichText.asText(doc.data.title) === 'Blue Ocean Navigator™' && (
                  <small>If you are already a subscriber of Blue Ocean Studio™, this course is included by default in your subscription!</small>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderEvents = () => {
    const { doc } = this.state;
    return (
      <div id="register" className="py-5 text-white pos-rlt">
        <div className="container">
          <h4 className="text-center text-primary mb-md-5 mb-3">Upcoming Workshops</h4>
          <div className="row px-5">
            { doc.data.events.map((data, index) => {
              const event = data.event;
              console.log(event);
              if (event.data && event.data.status === 'Open') {
                return (
                  <HashLink to={`/payment?pid=${doc.uid}&index=${index}`} className="col-md-4">
                    <EventCard event={event.data} />
                  </HashLink>
                )
              } else if (event.data) {
                return <div className="col-md-4"><EventCard event={event.data} /></div>
              }
            })}
          </div>
        </div>
      </div>
    )
  }

  renderPlans = () => {
    const { doc } = this.state;
    console.log(doc);
    return (
      <div id="register" className="py-5 pos-rlt">
        <div className="container text-center">
          <h4 className="text-center text-primary mb-3">{PrismicReact.RichText.asText(doc.data.price_tag)}</h4>
          <div>{PrismicReact.RichText.render(doc.data.price_description, this.props.prismicCtx.linkResolver)}</div>
          <div className="row px-5 my-5">
            { doc.data.pricing.map((data, index) => {
              console.log(data);
              return (
                <HashLink to={ data.disabled === 'Yes' ? '#' : `/payment?pid=${doc.uid}&index=${index}`} className={`plan-card mb-3 col-md-${12/doc.data.pricing.length}`}>
                  <Card style={{
                    opacity: data.disabled === 'Yes' && 0.8,
                    cursor: data.disabled === 'Yes' && 'default'
                  }} cover={<img class="py-4 mx-auto" style={{height:100, width: 'auto'}} alt={data.pricing_image.alt} src={data.pricing_image.url} />} hoverable={data.disabled === 'No'} className="text-center">
                    <Meta 
                      title={PrismicReact.RichText.asText(data.price_description)} 
                      description={data.disabled === 'Yes' && "Coming Soon"}
                    />
                  </Card>
                </HashLink>
              )
            })}
          </div>
          <p style={{textAlign:'center'}}>Choose a language to make your purchase</p>
        </div>
      </div>
    )
  }

  renderSuggestedSolutions = () => {
    return (
      <div className="my-md-5 py-3">
        <h5 className="product-section-title text-center font-weight-light text-uppercase mb-4">
          <span>Suggested Solutions</span>
        </h5>
        <p className="text-center px-md-4 font-weight-light">Here are a few other solutions we think you would like</p>
        <div className="row">
          { 
            this.state.doc.data.suggestions.map((item, index) => {
              return item.suggested_product.data && (
                <div className="col-lg-4 mb-3" key={index}>
                  <ProductCard item={item.suggested_product} index={index} />
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  render() {
    const { prismicCtx } = this.props;
    const { notFound, doc } = this.state;
    const data = doc && doc.data;

    if (data && data.product_status !== 'Inactive') {
      return (
        <div class="product-page">
          <Helmet>
            <title>{PrismicReact.RichText.asText(data.title)} | Blue Ocean Academy</title>
            <meta name="description" content={data.meta_description} />
            <meta property="og:title" content={PrismicReact.RichText.asText(data.title)} />
            <meta property="og:description" content={data.meta_description} />
            <meta property="og:image" content={data.card_image.url} />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:site_name" content="Blue Ocean Academy™" />
          </Helmet>
          <div className="product-bg" style={{backgroundImage:`url(${data.product_image.url})`}}>
            <div className="product-bg-overlay"></div>
            <div className="container py-5 product-bg-title">
              <h2 className="font-weight-normal text-primary mb-2">{PrismicReact.RichText.asText(data.title)}</h2>
              <div className="lead col-md-6 p-0">{PrismicReact.RichText.asText(data.subtitle)}</div>
              <div className="col-md-6 p-0">{PrismicReact.RichText.render(doc.data.description, this.props.prismicCtx.linkResolver)}</div>
              { data.pricing.length > 0 && <HashLink to="#register" className="btn btn-primary">{PrismicReact.RichText.asText(doc.data.price_tag)}</HashLink> }
            </div>
          </div>
          <section className="bg-light" style={{minHeight: '4px'}}></section>

          { data.overview.length !== 0 && (
            <Section>
              <div className="row">
                <div className={ data.preview_video && data.preview_video.url ? 'col-md-5 mb-4' : 'col-md-8 mx-auto'}>
                  {PrismicReact.RichText.render(data.overview, prismicCtx.linkResolver)}
                </div>
                { data.preview_video && data.preview_video.url && (
                  <div className="col-md-7">
                    <iframe className="w-100 shadow-lg bg-white" style={{height:390}} src={data.preview_video.url} title="Preview Video" frameBorder="0" allowFullScreen></iframe>
                  </div>
                )}
              </div>
            </Section>
          )}

          { data.why_blue_ocean.length !== 0 && (
            <Section className="bg-light">
              <div id="why-blue-ocean" className="row">
                <div className="col-md-8 mx-auto text-center">
                  {PrismicReact.RichText.render(data.why_blue_ocean, prismicCtx.linkResolver)}
                </div>
              </div>
            </Section>
          )}


          { (data.program_structure.length !== 0 || (data.program_structure_title && PrismicReact.RichText.asText(data.program_structure_title).length !== 0)) && (
            <Section>
              { data.program_structure_title && (
                <div className="col-md-8 mx-auto mb-5 text-center">
                  {PrismicReact.RichText.render(data.program_structure_title, prismicCtx.linkResolver)}
                </div>
              )}
              { data.program_structure.length !== 0 && (
                <div className="row">
                  { data.program_structure.map((item, index) => {
                    return (
                      <div key={index} className={data.program_structure.length <= 2 && index === 0 ? 'offset-lg-2 col-lg-4 col-md-6': 'col-lg-4 col-md-6'} style={{marginBottom: '25px'}}>
                        <Card 
                          style={{height: '100%', minHeight: '250px'}}
                          className={index % 2 !== 0 && 'bg-light'}
                          hoverable={true}
                          cover={item.structure_image.url &&
                            <div 
                              style={{
                                backgroundImage: `url(${item.structure_image.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '250px',
                                width: '100%',
                              }}
                            />
                          }
                        >
                          <h6>{PrismicReact.RichText.asText(item.structure_title)}</h6>
                          {PrismicReact.RichText.render(item.structure_body, prismicCtx.linkResolver)}
                        </Card>
                      </div>
                    )
                  })}
                </div>
              )}
            </Section>
          )}
          
          { data.testimonials.length !== 0 && (
            <Section>
              <div className="text-center">
                <div className="bg-dark col-md-8 mx-auto mb-md-5 mb-3" style={{height: 1}}></div>
                <Carousel autoplay autoplaySpeed={6000}>
                {
                  data.testimonials.map((item, index) => {
                    return (
                      <div key={index} className="carousel-item" style={{minHeight: '200px'}}>
                        <p className="lead col-md-8 m-auto">
                          "{PrismicReact.RichText.asText(item.testimonial_quote)}"
                        </p>
                        <div className="font-weight-light py-3 pb-4">{PrismicReact.RichText.asText(item.testimonial_name)}</div>
                      </div>
                    )
                  })
                }
                </Carousel>
                <div className="bg-dark col-md-8 mx-auto mb-md-5 mb-3" style={{height: 1}}></div>
              </div>
            </Section>
          )}

          { data.course_id && data.pricing.length === 1 && (
            <Section className="bg-light bg-overlay">{this.renderRegister()}</Section>
          )}

          { data.pricing.length > 1 && (
            <Section className="bg-light bg-overlay">{this.renderPlans()}</Section>
          )}

          { data.events.length > 0 && (
            <Section className="bg-light bg-overlay">{this.renderEvents()}</Section>
          )}

          <Section className="bg-primary text-white text-center">
            { data.enquire.length === 0 && (
              <>
                {/* <h4 className="text-white">Enquiry</h4> */}
                <p className="mb-5">Have a question? Contact us and we'll be happy to help you!</p>
              </>
            )}
            { data.enquire.length !== 0 && <p className="mb-5">{PrismicReact.RichText.render(data.enquire, prismicCtx.linkResolver)}</p> }
            <ContactModal>Contact Us</ContactModal>
          </Section>
        </div>
      );
    } else if (notFound || (doc && data.product_status === 'Inactive')) {
      return <NotFound />;
    }
    return <div className="container py-5"><Skeleton /></div>;
  }
}

export default withFirebase(Product);