import React from 'react';
import NotFound from './NotFound';
import { RichText } from 'prismic-reactjs';
import { Skeleton } from 'antd';

// Declare your component
export default class NewsArticle extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      doc: null,
      notFound: false,
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentWillReceiveProps(nextProps){
    if (this.props.prismicCtx !== nextProps.prismicCtx ||
      nextProps.location.pathname !== this.props.location.pathname) {
      this.fetchPage(nextProps);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    this.setState({ doc: null });
    
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('news_article', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
          document.title = `${RichText.asText(doc.data.title)} | Blue Ocean Academy`;
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {
    const { doc, notFound } = this.state;
    const { prismicCtx } = this.props;

    if (doc) {
      return (
        <div>
          <div className="product-bg" style={{minHeight: 250, backgroundImage:`url(${doc.data.image.url})`}}>
            <div className="product-bg-overlay"></div>
            <div className="col-lg-8 col-md-10 mx-auto py-5 product-bg-title">
              <h2 className="col-md-10 p-0 font-weight-normal text-primary mb-2">{RichText.asText(doc.data.title)}</h2>
              <div className="col-md-8 p-0">{RichText.render(doc.data.summary, prismicCtx.linkResolver)}</div>
            </div>
          </div>
          <section className="bg-primary" style={{minHeight:'4px'}}></section>
          <section className="col-lg-8 col-md-10 mx-auto py-5">
            <div style={{fontSize:'16px'}}>{RichText.render(doc.data.body, prismicCtx.linkResolver)}</div>
            <div className="my-5 text-right" style={{fontSize:'16px'}}>
              <strong>{RichText.render(doc.data.authors, prismicCtx.linkResolver)}</strong>
            </div>
            {doc.data.footnotes && (<>
              <div className="col-lg-4 col-md-6 mt-5 mb-2 bg-primary" style={{minHeight:'4px'}}></div>
              <strong id="footnotes">Footnotes:</strong>
              <div className="">{RichText.render(doc.data.footnotes, prismicCtx.linkResolver)}</div>
            </>)}
          </section>
        </div>
      );
    } else if (notFound) {
      return <NotFound />;
    }
    return <div class="container py-5"><Skeleton /></div>;
  }
}