import React from 'react';
import { Select } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { Icon } from 'antd';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      to: props.to !== undefined ? props.to : 'customerservice@bogn.com',
      referrer: props.country !== undefined ? props.country : props.referrer !== undefined ? props.referrer : 'General Enquiry',
      name: props.name !== undefined ? props.name : '',
      email: props.email !== undefined ? props.email : '',
      phone: '',
      location: '',
      country: '',
      message: '',
      source: 'N/A'
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    if (this.validator.allValid()) {
      this.props.onSubmit(this.state);
      // reset fields
      this.setState({
        name: '',
        email: '',
        phone: '',
        location: '',
        country: '',
        message: '',
        source: 'N/A'
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          { this.props.countries && (
            <div className="form-group col-12">
              <Select required size="large" className="w-100" value={this.state.referrer} onSelect={(referrer) => this.setState({referrer: referrer})}>
                <Select.Option value="General Enquiry">General Enquiry</Select.Option>
                { this.props.countries.map((item, key) => {
                  return <Select.Option key={key} value={item.label}><img width="25px" className="mr-2" src={`/images/flags/${item.flag}`} alt={item.label}/>{item.label}</Select.Option>;
                })}
              </Select>
            </div>
          )}
          <div className="form-group col-12">
            <input type="text" className="form-control" placeholder="Full Name" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
            {this.validator.message('name', this.state.name, 'required|alpha_space')}
          </div>
          <div className="form-group col-md-6">
            <input type="email" className="form-control" placeholder="Email Address" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
            {this.validator.message('email', this.state.email, 'required|email')}
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="Phone Number" value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} />
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="City" value={this.state.location} onChange={(e) => this.setState({location: e.target.value})} />
            {this.validator.message('city', this.state.location, 'required|alpha_space')}
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="Country" value={this.state.country} onChange={(e) => this.setState({country: e.target.value})} />
            {this.validator.message('country', this.state.country, 'required|alpha_space')}
          </div>
        </div>
        <div className="form-group">
          <textarea rows="6" type="text" className="form-control" placeholder="Enquiry Message" value={this.state.message} onChange={(e) => this.setState({message: e.target.value})}></textarea>
          {this.validator.message('message', this.state.message, 'required')}
        </div>
        <div className="form-group">
          <Select placeholder="How did you hear about us?" size="large" className="w-100" onSelect={(source) => this.setState({source: source})}>
            <Select.Option value="Google">Google</Select.Option>
            <Select.Option value="Newsletter">Newsletter</Select.Option>
            <Select.Option value="Social Media">Social Media</Select.Option>
            <Select.Option value="College/Friend/Relative">College/Friend/Relative</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </div>
        <div className="text-center">
          <button className="btn btn-primary px-5" onClick={this.handleSubmit}><Icon type="mail" className="mr-2" />Send</button>
        </div>
      </form>
    );
  }
}

export default ContactForm;