import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Layout, Icon } from 'antd';

import NavMenu from './components/NavMenu';
import Preview from './pages/Preview';
import NotFound from './pages/NotFound';
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Studio from './pages/Studio';
import Course from './pages/Course';
import Invite from './pages/Invite';
import Payment from './pages/Payment';
import PaymentSuccess from './pages/PaymentSuccess';
import Product from './pages/Product';
import FAQs from './pages/Faqs';
import Resource from './pages/Resource';
import ContactUs from './pages/ContactUs';

import Training from './pages/Training';
import Consulting from './pages/Consulting';
import NewsArticle from './pages/NewsArticle';

import 'antd/dist/antd.css';  // or 'antd/dist/antd.less'
import ContactModal from './components/ContactModal';
import moment from 'moment';

const {
  Footer, Content,
} = Layout;

class App extends React.Component {
  state = {};

  render() {
    const props = this.props;
    const { readMore } = this.state;
    return (
      <Router>
        <Layout>
          <NavMenu prismicCtx={props.prismicCtx} />
          <Content className="bg-white">
            <Switch>
              <Redirect from="/academy/blue-ocean-studio-101" to="/studio" />
              <Redirect from="/academy/:uid" to="/product/:uid" />
              <Redirect from="/blue-ocean-academy" to="/" />
              <Route path="/product/blue-ocean-practical-introduction" component={() => {
                window.location.href = 'https://learn.blueoceanstrategy.com/blue-ocean-strategy-online-course';
                return null;
              }} />
              <Route path="/preview" render={routeProps => <Preview {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route exact path="/" render={routeProps => <Homepage {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/login" render={routeProps => <Login {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/forgot-password" render={routeProps => <ForgotPassword {...routeProps} prismicCtx={props.prismicCtx} />} />
              {/* <Route path="/studio" render={routeProps => <Studio {...routeProps} prismicCtx={props.prismicCtx} />} /> */}
              <Route path="/studio" component={() => {
                window.location.href = 'https://learn.blueoceanstrategy.com/blue-ocean-studio';
                return null;
              }} />
              {/* <Route exact path="/product/blue-ocean-practical-introduction" render={routeProps => <Course {...routeProps} prismicCtx={props.prismicCtx} />} /> */}
              <Route path="/training" render={routeProps => <Training {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/consulting" render={routeProps => <Consulting {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/invite" render={routeProps => <Invite {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/payment" render={routeProps => <Payment {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/payment-success" render={routeProps => <PaymentSuccess {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/product/:uid" render={routeProps => <Product {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/faqs" render={routeProps => <FAQs {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/contact-us" render={routeProps => <ContactUs {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/news-article/:uid" render={routeProps => <NewsArticle {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route path="/:uid" render={routeProps => <Resource {...routeProps} prismicCtx={props.prismicCtx} />} />
              <Route component={NotFound} />
            </Switch>
          </Content>
          <Footer className="footer bg-white">
            <div className="container">
              <div className="row my-5">
                <div className="col-12 col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img className="img-fluid logo" src="/images/logo.png" alt="Blue Ocean Academy™ Logo" />
                  {/* <small className="d-block mb-3 text-muted mt-3">
                    <p>Blue Ocean Academy™ is the official blue ocean online learning, training, and consulting provider under the Blue Ocean Global Network™. Blue Ocean Academy™ works with small businesses, large established companies, cash-strapped start-ups, non-profits, education institutions, and individuals to shift their perspective from competing to creating, transform businesses, and create blue oceans. </p>
                    { readMore ? (<>
                      <p>We<strong>&nbsp;empower </strong>you with tools to<strong> innovate, transform, and grow your business. </strong>&nbsp;With a range of solutions to suit your unique needs and dedicated team of experienced blue ocean experts, you’ll be set to create innovative ideas that will put you and your business on a sustained growth trajectory. &nbsp;</p>
                      <a style={{fontWeight: 'bold'}} onClick={() => this.setState({readMore: false})}>Read less</a>
                    </>) : <a style={{fontWeight: 'bold'}} onClick={() => this.setState({readMore: true})}>Read more</a>}
                  </small> */}
                </div>
                <div className="col-sm-6 col-md-2">
                  <h6 className="font-weight-bold">SUPPORT</h6>
                  <ul className="list-unstyled text-small">
                    <li><ContactModal link="Contact Us" referrer={`Footer on ${window.location.href}`} /></li>
                    <li><Link className="text-muted" to="/faqs">FAQs</Link></li>
                    <li><Link className="text-muted" to="/privacy">Privacy</Link></li>
                    <li><Link className="text-muted" to="/trademarks">Trademarks</Link></li>
                  </ul>
                </div>
                <div className="col-ms-6 col-md-3">
                  <h6 className="font-weight-bold">BLUE OCEAN ACADEMY™</h6>
                  <ul className="list-unstyled text-small">
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TheOfficialBlueOceanAcademy"><Icon type="facebook" className="mr-2" />TheOfficialBlueOceanAcademy</a></li>
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/the-blue-ocean-academy"><Icon type="linkedin" className="mr-2" />the-blue-ocean-academy</a></li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3">
                  <h6 className="font-weight-bold">BLUE OCEAN STRATEGY</h6>
                  <ul className="list-unstyled text-small">
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BlueOceanStrategy1"><Icon type="facebook" className="mr-2" />BlueOceanStrategy1</a></li>
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://twitter.com/BlueOceanStrtgy"><Icon type="twitter" className="mr-2" />BlueOceanStrtgy</a></li>
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/blue-ocean-strategy"><Icon type="linkedin" className="mr-2" />blue-ocean-strategy</a></li>
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/c/blueoceanstrategy"><Icon type="youtube" className="mr-2" />blueoceanstrategy</a></li>
                    <li><a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/blueoceanstrtgy/"><Icon type="instagram" className="mr-2" />blueoceanstrtgy</a></li>
                  </ul>
                </div>
              </div>
  
              <div className="row">
                <div className="col-12"><hr /><small className="d-block mb-3 mt-3">Copyright © 2005-{moment().format('YYYY')} Chan Kim & Renée Mauborgne. All Rights Reserved.</small></div>
              </div>
            </div>
          </Footer>
        </Layout>
      </Router>
    )
  }
}

export default App;
