import React from 'react';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import { Skeleton, Tabs, Collapse, Icon } from 'antd';
import ContactModal from '../components/ContactModal';

import './Faqs.css';

const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;

const customPanelStyle = {
  background: '#f6f8fc',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

export default class Product extends React.Component {
  constructor(props){
    super(props);
    document.title = 'FAQ | Blue Ocean Academy';
    this.state = {
      doc: null,
      notFound: false,
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentWillReceiveProps(nextProps){
    this.fetchPage(nextProps);
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getSingle('faq').then(doc => {
        if (doc) {
          let categories = [];
          let categoryNames = [];
          Object.keys(doc.data.faqs_questions).forEach(key => {
            var item = doc.data.faqs_questions[key];
            var _categories = PrismicReact.RichText.asText(item.faq_categories).split(',');
            _categories.forEach(_category => {
              var index = categoryNames.indexOf(_category.trim());
              if (index === -1) {
                categoryNames.push(_category.trim());
                categories.push([]);
                index = categoryNames.length - 1;
              }
              categories[index].push(item);
            });
          });

          this.setState({ doc, categoryNames, categories });
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      return (
        <div>
          <div className="col-md-8 mx-auto text-center p-lg-4">
            <h1 className="font-weight-normal text-primary mb-3" style={{marginTop: '80px'}}>{PrismicReact.RichText.asText(this.state.doc.data.faqs_title)}</h1>
            <div className="lead pb-4">{PrismicReact.RichText.asText(this.state.doc.data.faqs_description)}</div>
          </div>
          { this.state.categories && (
            <Tabs animated={false} tabBarStyle={{}} className="col-md-10 mx-auto">
              { this.state.categoryNames.map((category, index) => {
                return (
                  <TabPane tab={category} key={index} className="col-md-10 mx-auto">
                    <Collapse 
                      accordion
                      bordered={false}
                      expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                    >
                    {
                      this.state.categories[index].map((item, index) => {
                        return (
                          <Panel style={customPanelStyle} header={PrismicReact.RichText.asText(item.faq_question)} key={index}>
                            {PrismicReact.RichText.render(item.faq_answer, this.props.prismicCtx.linkResolver)}
                          </Panel>
                        )
                      })
                    }
                    </Collapse>
                  </TabPane>
                )
              })}
            </Tabs>
          )}
          <div className="text-center py-5">
            <h5 className="font-weight-light mb-4">Can’t find what you’re looking for?</h5>
            <ContactModal referrer="FAQ">Contact Us</ContactModal>
          </div>
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <div className="container py-5"><Skeleton /></div>;
  }
}