import {  } from 'react';
import axios from 'axios';

export const login = (data) => {
  return axios.post(`https://api.go1.co/user/account/login`, {
    portal: process.env.REACT_APP_GO1_INSTANCE_URL,
    ...data
  }).then(resp => {
    console.log(resp);
    return resp;
  });
};

export const forgotPassword = (email) => {
  return axios.post(`https://api.go1.co/user/password/${process.env.REACT_APP_GO1_INSTANCE_URL}/${email}`, {});
};

export const lookup = (email) => {
  return axios.get(`https://api.go1.co/user/account/found/${process.env.REACT_APP_GO1_INSTANCE_URL}/${email}`);
};

export const registerUser = (data) => {
  return axios.post(`https://api.go1.co/user/account`, {
    instance: 'accounts.gocatalyze.com',
    portal: process.env.REACT_APP_GO1_INSTANCE_URL,
    email: data.email,
    password: data.password,
    first_name: data.first_name,
    last_name: data.last_name,
  });
};

export const updateEck = (account, fields) => {
  var uiid = account.accounts[0].id;
  for (var i=0; i<account.accounts.length; i++) {
    if (account.accounts[i].instance_name === process.env.REACT_APP_GO1_INSTANCE_URL) {
      uiid = account.accounts[i].id;
      break;
    }
  }

  axios.get(`https://api.go1.co/eck/entity/${process.env.REACT_APP_GO1_INSTANCE_URL}/account/${uiid}`, {
    headers: {
      'Authorization': `Bearer ${account.jwt}`,
      'Content-Type': 'application/json',
    }
  }).then(data => {
      delete data.instance;
      delete data.entity_type;
      delete data.id;
      var body = {
        fields: data
      };
      Object.keys(fields).map(key => {
        return body.fields[key] = fields[key];
      });

      return axios.post(`https://api.go1.co/eck/entity/${process.env.REACT_APP_GO1_INSTANCE_URL}/account/${uiid}`, body, {
        headers: {
          'Authorization': `Bearer ${account.jwt}`,
          'Content-Type': 'application/json',
        }
      });
    })
    .catch(err => {
      var body = {
        fields: {}
      };
      Object.keys(fields).map(key => {
        return body.fields[key] = fields[key];
      });

      return axios.post(`https://api.go1.co/eck/entity/${process.env.REACT_APP_GO1_INSTANCE_URL}/account/${uiid}`, body, {
        headers: {
          'Authorization': `Bearer ${account.jwt}`,
          'Content-Type': 'application/json',
        }
      });
    });

};