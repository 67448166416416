import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_WORDPRESS_BACKEND}/wp-json/bogn/v1`;
const headers = {
  'Content-Type': 'application/json'
};

export const createEnquiry = (data) => {
  return axios.post(`${apiUrl}/contact-us`, data, {
    headers: headers
  });
};