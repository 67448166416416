import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import Prismic from 'prismic-javascript';
import { Link as PrismicLink, RichText } from 'prismic-reactjs';
import qs from 'query-string';
import { Modal, Tooltip, Skeleton, Carousel, Button } from 'antd';

import ProductCard from '../components/ProductCard';
import ContactModal from '../components/ContactModal';

import { countries } from '../services/countries';

import './Homepage.css';

const Section = (props) => {
  return (
    <div className={props.left ? 'product-bg left' : 'product-bg'} style={{backgroundImage:`url(${props.backgroundImage})`}}>
      <div className="product-bg-overlay"></div>
      <div className="container py-5 product-bg-title">
        <div class={props.left ? 'offset-lg-6 col-lg-5' : 'offset-lg-1 col-lg-4'}>{props.children}</div>
      </div>
    </div>
  )
};

export default class Homepage extends React.Component {

  state = {
    tabIndex: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).s,
    countries: countries()
  };

  componentDidMount() {
    if (this.props.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getSingle('homepage').then((doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  fetchProducts(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'product'),
        { orderings : '[my.product.weight]' }
      ).then(response => {
        let products = {
          shift: [],
          solve: [],
          transform: []
        };
        response.results.forEach(item => {
          if (products[item.data.type]) {
            products[item.data.type].push(item);
          }
        });
        this.setState({ products });
      });
    }
    return null;
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      shown: true,
    });
  }

  handleInactiveProduct = (item) => {
    if (item.data.product_status && RichText.asText(item.data.product_status) === 'inactive') {
      console.log('show contact modal');
    }
  }

  render() {
    const { prismicCtx } = this.props;
    const { doc } = this.state;
    if (!doc) {
      return <div className="container py-5"><Skeleton /></div>
    }
    return (
      <div id="homepage">
        <Helmet>
          <title>Blue Ocean Academy™</title>
          <meta name="description" content="The Official Blue Ocean Online Learning, Training, Coaching, Workshop, Webinar, Seminar, Executive Roundtable, Consulting & Leadership Transformation" />
          <meta property="og:title" content="Blue Ocean Academy™" />
          <meta property="og:description" content="The Official Blue Ocean Online Learning, Training, Coaching, Workshop, Webinar, Seminar, Executive Roundtable, Consulting & Leadership Transformation" />
          <meta property="og:image" content="https://bogn.cdn.prismic.io/bogn/5a4c3e0b9ef561e90b37f14a0d23a530e00dda02_pi-page.jpg" />
          <meta property="og:url" content="https://bogn.com" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Blue Ocean Academy™" />
          <meta name="twitter:image:alt" content="Blue Ocean Academy™" />
        </Helmet>
        <section id="splash" className="homepage-title text-center py-lg-5"
          style={{
            backgroundImage: 'url(/images/homepage-overlay.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="col-lg-10 col-md-8 py-5 mx-auto">
            <h1 className="font-weight-normal text-primary">Blue Ocean Global Network™</h1>
            <div className="px-md-5">{RichText.render(doc.data.academy_tagline, prismicCtx.linkResolver)}</div>
          </div>
        </section>
        {/* {doc.data.featured_news_summary && (
          <section className="text-center bg-primary">
            <div className="col-lg-10 col-md-8 py-5 mx-auto">
              <div className="mb-4">{RichText.render(doc.data.featured_news_summary, prismicCtx.linkResolver)}</div>
              <Button size="small" href={PrismicLink.url(doc.data.featured_news_link, prismicCtx.linkResolver)}>Read More</Button>
            </div>
          </section>
        )} */}
        <section className="row m-0">
          <a href={doc.data.training_url.url} target="_blank" className="col-md-6 p-0 bg" style={{height:390}}>
            <div className="img" style={{'background-image': `url(${doc.data.training_image.url})`}} />
            <div className="overlay font-weight-semibold">
              <div className="col-md-8 mx-auto lead">{RichText.render(doc.data.training_tagline, prismicCtx.linkResolver)}</div>
              <Button>Learn more</Button>
            </div>
          </a>
          <a href={doc.data.consulting_url.url} target="_blank" className="col-md-6 p-0 bg" style={{height:390}}>
            <div className="img" style={{'background-image': `url(${doc.data.consulting_image.url})`}} />
            <div className="overlay font-weight-semibold">
              <div className="col-md-8 mx-auto lead">{RichText.render(doc.data.consulting_tagline, prismicCtx.linkResolver)}</div>
              <Button>Learn more</Button>
            </div>
          </a>
        </section>
        {/* <Section backgroundImage={doc.data.training_image.url}>
          <h4 className="text-primary">Training</h4>
          <p className="mb-4 lead">{RichText.render(doc.data.training_tagline, prismicCtx.linkResolver)}</p>
          <Link to="/training" className="btn btn-primary">Our Solutions</Link>
        </Section>

        <Section left backgroundImage={doc.data.consulting_image.url}>
          <h4 className="text-primary">Consulting</h4>
          <p className="mb-4 lead">{RichText.render(doc.data.consulting_tagline, prismicCtx.linkResolver)}</p>
          <Link to="/consulting" className="btn btn-primary">Our Services</Link>
        </Section> */}

        {/* <Section left backgroundImage={doc.data.practical_introduction_image.url}>
          <div>{RichText.render(doc.data.practical_introduction_tagline, prismicCtx.linkResolver)}</div>
        </Section> */}
        
        <section className="bg-light">
          <div className="container">
            <div className="row text-center py-5">
              <div className="col-md-3">
                <div>
                  <object width="110px" data="/images/stats-books.svg" aria-label="projects" type="image/svg+xml">Your browser doesn't support object</object>
                </div>
                <div>
                  <h3 className="mt-3 mb-1">4 million+</h3>
                  <h6>books sold<br/>in 46 languages</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <object width="110px" data="/images/stats-users.svg" aria-label="users" type="image/svg+xml">Your browser doesn't support object</object>
                </div>
                <div>
                  <h3 className="mt-3 mb-1">260,000+</h3>
                  <h6>users<br/>trained online</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="">
                  <object width="110px" data="/images/stats-projects.svg" aria-label="projects" type="image/svg+xml">Your browser doesn't support object</object>
                </div>
                <div>
                  <h3 className="mt-3 mb-1">20,000+</h3>
                  <h6>projects<br/>created within the Studio</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <object width="110px" data="/images/stats-organizations.svg" aria-label="organizations" type="image/svg+xml">Your browser doesn't support object</object>
                </div>
                <div>
                  <h3 className="mt-3 mb-1">280+</h3>
                  <h6>organizations<br/>across the globe</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="who-are-we">
          <div className="container">
            <div className="row py-md-5">
              <div className="offset-lg-2 col-lg-8 py-4 lead">
                {RichText.render(doc.data.why_blue_ocean_academy, prismicCtx.linkResolver)}
              </div>
            </div>
          </div>
        </section> */}

        <section className="text-primary py-5">
          <div className="container">
            <h4 className="text-primary text-center">Some of our Customers</h4>
            <Carousel autoplay={true} dots={true}>
              { doc.data.why_blue_ocean_academy_images && doc.data.why_blue_ocean_academy_images.map(image => {
                return <div><img src={image.logos.url} alt={image.logos.alt} className="col-12 mx-auto" /></div>
              })}
            </Carousel>
          </div>
        </section>

        <section id="partners" className="homepage-studio py-4 p-sm-5 bg-primary">
          <div className="container text-center text-white">
            <div className="mt-3 col-md-10 mx-auto lead">{RichText.render(doc.data.partners_and_faculty, prismicCtx.linkResolver)}</div>
            <div className="pos-rlt">
              { this.state.countries.map((item, index) => {
                return (
                  <Tooltip
                    getPopupContainer={() => document.body}
                    key={index}
                    trigger="hover"
                    zIndex={2}
                    title={
                      <div><div className="country-label"><img width="30px" className="mr-1" src={ `/images/flags/${item.flag}` } alt={item.label} /><div><b>{ item.label }</b></div></div><div className="enquire-label"><ContactModal countries={this.state.countries} country={item.label} link="Click here to enquire" /></div></div>
                    }
                  >
                    <div style={{position: 'absolute', top: item.top, left: item.left, width: '7px', height: '7px', zIndex: 9999}}></div>
                  </Tooltip>
                )
              })}
              <img className="img-fluid col-md-12 mx-auto p-0" src="/images/map.svg" alt="Authorised Partners and Senior Faculty Map" />
            </div>
            { doc.data.contact_us && <div className="mb-5 col-md-10 mx-auto lead">{RichText.render(doc.data.contact_us, prismicCtx.linkResolver)}</div> }
            <div>
              {/* <ContactModal countries={this.state.countries}>Learn more</ContactModal> */}
              <a href="https://weareblueocean.com/" className={`btn btn-outline-primary`}>Learn More</a>
            </div>
          </div>
        </section>

        { this.state.products && !this.state.shown && (
          <Modal
            visible={this.state.visible}
            onCancel={this.handleCancel}
            width={780}
            footer={null}
            style={{ top: 20 }}>
            <div className="modal-body">
              <h3 className="text-center text-primary font-weight-bold m-auto">Open for Enrolment Now!</h3>
              <div className="row py-4">
                <div className="col-md-6 p-3">
                  <ProductCard item={this.state.products.shift[0]} index={0} />
                </div>
                <div className="col-md-6 p-3">
                  <ProductCard item={this.state.products.shift[1]} index={1} />
                </div>
              </div>
              <div className="text-center mx-lg-5">
                <div className="font-weight-light mb-2">
                  <Link onClick={this.handleCancel} to="#solutions" smooth>Click here to explore other solutions</Link>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}