import React from 'react';
import qs from 'query-string';
import ContactForm from '../components/ContactForm';
import { Icon, notification } from 'antd';
import { createEnquiry } from '../services/bogn';

export default class ContactUs extends React.Component {
  constructor(props){
    super(props);
    this.state = {};

    this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  handleSubmit = (values) => {
    createEnquiry(values).then(result => {
      notification.open({
        message: 'Enquiry Sent!',
        description: 'Thank you for your enquiry. Our team will be in touch with you within the next 24 hours with further assistance.',
        icon: <Icon type="check-circle" style={{ color: '#33b73f' }} />,
        placement: 'bottomLeft',
      });
      window.ga('send', 'event', {
        eventCategory: 'enquiry',
        eventAction: 'sent',
        eventLabel: this.state.referrer
      });
    });
  }

  render() {
    return (
      <div className="container py-5">
        <h2 className="text-center mb-5">Contact Us</h2>
        <div className="col-md-6 mx-auto">
          <ContactForm onSubmit={this.handleSubmit} {...this.query} />
        </div>
      </div>
    );
  }
}