import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Prismic from 'prismic-javascript';
import PrismicReact from 'prismic-reactjs';
import { Affix, Menu, Dropdown, Icon, Modal } from 'antd';
import ProductCard from './ProductCard';

// Declare your component
export default class NavMenu extends Component {
  constructor(props){
    super(props);
    if (props.prismicCtx) {
      this.fetchNav(props);
    }

    this.state = {
      menuVisible: false
    };
  }

  componentDidUpdate(prevProps) {
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchNav(this.props);
    }
  }

  fetchNav(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'product'),
        { orderings : '[my.product.weight]' }
      ).then(response => {
        let products = {};
        response.results.forEach(item => {
          if (item.data.type) {
            if (!products[item.data.type]) {
              products[item.data.type] = [];
            }
            products[item.data.type].push(item);
          }
        });
        this.setState({ products });
      });
    }
    return null;
  }

  handleClick(event, item) {
    if (item.data.product_status === 'Inactive') {
      event.preventDefault();
      this.setState({menuVisible: false, modalVisible: true, modalItem: item});
    } else {
      this.setState({menuVisible: false});
    }
  }

  render() {
    const { products } = this.state;
    return (
      <Affix offsetTop={0}>
        <nav className="navbar navbar-expand-lg bg-white shadow px-4 py-3">
          <Link to='/'><img style={{ width: '220px' }} src="/images/logo.png" alt="Blue Ocean Waves" /></Link>
          <button className="navbar-toggler collapsed" type="button" onClick={e => this.setState({menuVisible: !this.state.menuVisible})}>
            <Icon type="bars" />
          </button>
          <div className="navbar-collapse collapse" style={{display: this.state.menuVisible ? 'inline-block' : 'none'}}>
            <div className="mr-auto"></div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href='https://www.blueoceanstrategy.com/' className="font-weight-bold text-dark btn">Shift | Strategy | Leadership</a>
              </li>
              <li className="nav-item">
                <a href="https://weareblueocean.com/" className="font-weight-bold text-dark btn mr-3">WE ARE BLUE OCEAN</a>
              </li>

              {/* { products && Object.keys(products).map(type => {
                return (
                  <li key={type} className="nav-item">
                    <Dropdown trigger={['click']} overlay={
                      <Menu>
                        { products[type].map((item) => {
                          return (<Menu.Item key={item.uid}>
                            {item.uid === 'blue-ocean-practical-introduction' ? (
                              <a href="https://learn.blueoceanstrategy.com/blue-ocean-strategy-online-course" className="font-weight-bold">{PrismicReact.RichText.asText(item.data.title)}</a>
                            ) : (
                              <Link to={`/product/${item.uid}`} onClick={e => this.handleClick(e, item)} className="font-weight-bold">{PrismicReact.RichText.asText(item.data.title)}</Link>
                            )}
                          </Menu.Item>)
                        })}
                      </Menu>
                    }>
                      <span className="ant-dropdown-link font-weight-bold text-dark btn">
                        {type}<Icon type="caret-down" className="ml-2" />
                      </span>
                    </Dropdown>
                  </li>
                )
              })} */}
              <li className="nav-item">
                <Link to='/login' onClick={e => this.setState({menuVisible: false})} className={`btn btn-primary ${this.state.menuVisible ? 'btn-block' : ''}`}>Login</Link>
              </li>
              {/* <li className="nav-item">
                <Link to='/studio#pricing' onClick={e => this.setState({menuVisible: false})} className={`btn btn-primary ${this.state.menuVisible ? 'btn-block' : ''}`}>7-Days Free Trial</Link>
              </li> */}
            </ul>
          </div>
        </nav>

        <Modal
          visible={this.state.modalVisible}
          onCancel={() => {this.setState({modalVisible: false})}}
          footer={null}
        >
          <h4 class="text-center text-primary font-weight-bold mb-3" style={{textTransform: 'uppercase'}}>Coming Soon!</h4>
          <ProductCard className="no-shadow" item={this.state.modalItem} />
        </Modal>
      </Affix>
    );
  }
}