import React from 'react';
import ReactDOM from 'react-dom';
import PrismicApp from './PrismicApp';
import Firebase, { FirebaseContext } from './components/Firebase';
import './index.css';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <PrismicApp />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
