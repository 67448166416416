import React, { Component } from 'react';
import { Modal, Icon, notification } from 'antd';
import { createEnquiry } from '../services/bogn';
import ContactForm from './ContactForm';

// Declare your component
export default class ContactModal extends Component {
  constructor (props) {
    super(props);

    this.state = {
      referrer: props.country !== undefined ? props.country : props.referrer !== undefined ? props.referrer : 'General Enquiry',
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  handleOk = (values) => {
    createEnquiry(values)
      .then(
        (result) => {
          console.log(result);
          this.setState({
            visible: false,
          });
          notification.open({
            message: 'Enquiry Sent!',
            description: 'Thank you for your enquiry. Our team will be in touch with you within the next 24 hours with further assistance.',
            icon: <Icon type="check-circle" style={{ color: '#33b73f' }} />,
            placement: 'bottomLeft',
          });
          window.ga('send', 'event', {
            eventCategory: 'enquiry',
            eventAction: 'sent',
            eventLabel: this.state.referrer
          });
          this.handleCancel();
        }
      );
  }

  render() {
    return (
      <div>
        { this.props.link === undefined && (
          <button {...this.props} className={`btn btn-outline-primary ${this.props.className}`} onClick={this.showModal}>
            {this.props.children}
          </button>
        )}
        { this.props.link && (
          <a className="text-muted" onClick={this.showModal}>
            {this.props.link}
          </a>
        )}
        <Modal
          title="Contact Us"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ContactForm {...this.props} onSubmit={this.handleOk} />
        </Modal>
      </div>
    );
  }
}