import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { withFirebase } from "../components/Firebase";
import { injectStripe, CardElement } from "react-stripe-elements";
import {
  Button,
  Input,
  Divider,
  Icon,
  Checkbox,
  Alert,
  notification,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";

import { registerUser, updateEck } from "../services/user";
import {
  getCoupon,
  getSubscription,
  createSubscription,
  createPayment,
} from "../services/backend";

const createOptions = (padding) => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#000000a6",
        letterSpacing: "0.025em",
        fontWeight: 100,
        fontFamily: "Open Sans, sans-serif",
        "::placeholder": {
          color: "#ccc",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.account = props.account;
    this.user = props.user;
    this.product = props.product;
    this.recaptchaRef = props.recaptchaRef;

    this.state = {
      loading: false,
      cardHolderName: "",
      renewal: true,
      terms: false,
      recaptchaConfirmed: false,
      amount: this.product.price,
    };

    if (this.account) {
      getSubscription(this.account.jwt).then(({ stripe }) => {
        if (stripe) {
          this.setState({
            activeSub: true,
            renewalDate: stripe.current_period_end * 1000,
          });
        }
      });
    }
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  handleCaptchaOnChange = (token) => {
    this.setState({ recaptchaConfirmed: token });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({ loading: true });

    this.coupon = this.state.coupon ? this.state.coupon.id : null;

    if (!this.account) {
      registerUser({
        email: this.user.email,
        password: this.user.password,
        first_name: this.user.fname,
        last_name: this.user.lname,
      })
        .then((result) => {
          this.account = result.data;
          this.handlePayment();
        })
        .catch((err) => {
          this.setState({
            loading: false,
            formError: err.response.data.message,
          });
        });
    } else {
      this.handlePayment();
    }
  };

  handlePayment = () => {
    this.props.stripe
      .createToken({
        type: "card",
        owner: {
          name: this.state.cardHolderName,
        },
      })
      .then(({ token }) => {
        if (this.product.studioAccess) {
          updateEck(this.account, {
            field_studio_subscription: [
              { value: this.product.studioSubscription },
            ],
            field_studio_access: [{ value: this.product.studioAccess }],
            field_activation_validity: [{ value: 0 }],
            field_source: [
              { value: `${this.product.description}:${this.product.courseId}` },
            ],
          });
        } else {
          updateEck(this.account, {
            field_source: [
              { value: `${this.product.description}:${this.product.courseId}` },
            ],
          });
        }

        if (this.product.plan) {
          createSubscription(
            this.account.jwt,
            token.id,
            this.state.cardHolderName,
            this.product,
            this.coupon,
            this.state.renewal
          )
            .then(() => {
              window.ga("send", "event", {
                eventCategory: "subscription",
                eventAction: "successful",
                eventLabel: `${this.product.description} : ${
                  this.account.mail
                }`,
              });
              this.context.router.history.push(
                `/payment-success?jwt=${this.account.jwt}`
              );
            })
            .catch((err) => {
              this.setState({ formError: err.message, loading: false });
            });
        } else {
          createPayment(
            this.account.jwt,
            token.id,
            this.state.cardHolderName,
            this.product
          )
            .then(() => {
              window.ga("send", "event", {
                eventCategory: "payment",
                eventAction: "successful",
                eventLabel: this.account.mail,
              });
              window.ga("send", "event", {
                eventCategory: `${this.product.description} - ${
                  this.product.event
                }`,
                eventAction: "successful",
                eventLabel: this.account.mail,
              });
              this.context.router.history.push(
                `/payment-success?jwt=${this.account.jwt}`
              );
            })
            .catch((err) => {
              this.setState({ formError: err.message, loading: false });
            });
        }
      })
      .catch((err) => {
        this.setState({ formError: err.message, loading: false });
      });
  };

  handleApplyCoupon = (couponCode) => {
    if (couponCode) {
      this.setState({ couponDisabled: true });
      getCoupon(couponCode)
        .then((result) => {
          var amount = this.product.price;
          var coupon = result.data;
          if (coupon.valid) {
            if (coupon.percent_off) {
              amount = ((100 - coupon.percent_off) / 100) * this.product.price;
            } else if (coupon.amount_off) {
              amount = this.product.price - coupon.amount_off / 100;
            }
          } else {
            coupon = null;
            notification.open({
              message: "Error",
              description: "This coupon is invalid",
              icon: <Icon type="close-circle" style={{ color: "#f15757" }} />,
              placement: "bottomLeft",
            });
          }
          this.setState({ coupon, amount, couponDisabled: false });
        })
        .catch((err) => {
          var amount = this.product.price;
          this.setState({ coupon: null, amount, couponDisabled: false });
          notification.open({
            message: "Error",
            description: err.response.data.message || "Coupon invalid",
            icon: <Icon type="close-circle" style={{ color: "#f15757" }} />,
            placement: "bottomLeft",
          });
        });
    }
  };

  getInstanceUserId(account) {
    let uiid = account.accounts[0].id;
    for (let i = 0; i < account.accounts.length; i++) {
      if (
        account.accounts[i].instance_name ===
        process.env.REACT_APP_GO1_INSTANCE_URL
      ) {
        uiid = account.accounts[i].id;
        break;
      }
    }
    return uiid;
  }

  render() {
    const { product } = this.props;
    const { activeSub, renewalDate, renewal } = this.state;
    return (
      <div className="mb-2">
        <Divider
          className="text-secondary mb-3 text-uppercase"
          style={{ fontSize: "14px" }}
        >
          Complete Payment Details
        </Divider>
        <div className="form-group">
          <Input
            size="large"
            placeholder="Card Holder Name"
            value={this.state.cardHolderName}
            onChange={(e) => this.setState({ cardHolderName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <CardElement
            className="ant-input ant-input-lg stripe-input"
            {...createOptions()}
            onChange={(card) => {
              this.setState({ stripeComplete: card.complete });
            }}
          />
        </div>
        <div className="form-group text-left w-100">
          <Input.Search
            placeholder="Coupon"
            enterButton="Apply"
            size="large"
            disabled={this.couponCode !== "" && this.state.couponDisabled}
            onSearch={this.handleApplyCoupon}
            suffix={
              this.state.coupon && this.state.coupon.valid ? (
                <Icon type="check-circle" style={{ color: "#33b73f" }} />
              ) : (
                ""
              )
            }
          />
          <div className="text-xs coupon-message" />
        </div>
        {this.product.plan && (
          <div className="mb-3">
            <Checkbox
              checked={renewal}
              onChange={(e) => {
                this.setState({ renewal: e.target.checked });
              }}
            >
              I would like my subscription to be auto-renewed
            </Checkbox>
          </div>
        )}
        <div className="mb-3">
          <Checkbox
            onChange={(e) => {
              this.setState({ terms: e.target.checked });
            }}
          >
            I have read and agreed to the{" "}
            <a target="_blank" href={product.tos}>
              Terms &amp; Conditions of Sale
            </a>
          </Checkbox>
        </div>
        {activeSub ? (
          <div className="text-secondary mb-2">
            You currently have an active subscription ending{" "}
            <b>{Moment(renewalDate).format("LL")}</b>. You will be billed{" "}
            <b>USD ${this.state.amount.toFixed(2)}</b> at the end of your
            current subscription to Blue Ocean Global Network
          </div>
        ) : (
          <div className="text-secondary mb-2">
            You are about to pay <b>USD ${this.state.amount.toFixed(2)}</b> to
            Blue Ocean Global Network
          </div>
        )}
        <div className="mb-3">
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey="6LeYeWgcAAAAAISgOtHzMad591Qo4k4g10_4w6iB"
            onChange={this.handleCaptchaOnChange}
          />
        </div>
        <Button
          role="button"
          className="btn-block"
          type="primary"
          onClick={this.handleSubmit}
          disabled={
            !this.state.terms ||
            !this.state.stripeComplete ||
            !this.state.recaptchaConfirmed
          }
          loading={this.state.loading}
        >
          {activeSub ? "Confirm" : "Pay"}
        </Button>
        {this.state.formError && (
          <Alert
            className="mt-3 text-left"
            message="Uh oh!"
            description={this.state.formError}
            type="error"
            showIcon
          />
        )}
      </div>
    );
  }
}

export default injectStripe(withFirebase(CheckoutForm));
