import React from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import SimpleReactValidator from "simple-react-validator";
import { Alert, Button, Input, Divider, Icon, Skeleton } from "antd";
import { withFirebase } from "../components/Firebase";
import { lookup, registerUser, login } from "../services/user";

const EMAIL_STEP = 0;
const ACCOUNT_STEP = 1;

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      error: false,
      pageLoading: true,
      step: EMAIL_STEP,
    };

    this.query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (this.query.id) {
      const [id, type] = this.query.id.split(":");
      this.props.firebase.getSharedProject(id, type).then((data) => {
        const project = data.toJSON();
        if (!project) {
          this.setState({
            error: true,
            pageLoading: false,
          });
        } else {
          this.setState({
            type,
            projectId: id,
            projectName: project.name,
            authorId: project.author,
            authorName: project.authorDetail.name,
            pageLoading: false,
          });
        }
      });
    } else {
      this.state = {
        error: true,
        pageLoading: false,
      };
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      this.setState({ loading: true, formError: null });
      switch (this.state.step) {
        case EMAIL_STEP:
          this.handleFirstStep();
          break;
        case ACCOUNT_STEP:
          this.handleSecondStep();
          break;
        default:
          console.log("Invalid step");
          this.setState({ loading: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleFirstStep = () => {
    lookup(this.state.email)
      .then((result) => {
        this.setState({
          loading: false,
          step: ACCOUNT_STEP,
          new: result.data.found === 0,
        });
      })
      .catch((err) => console.log(err));
  };

  handleSecondStep = () => {
    const {
      fname,
      lname,
      email,
      password,
      confirm,
      projectId,
      projectName,
      authorId,
    } = this.state;

    if (this.state.new && password !== confirm) {
      this.setState({
        loading: false,
        formError: "Password fields do not match.",
      });
      return;
    }
    if (!this.validatePassword(password)) {
      this.setState({
        loading: false,
        formError:
          "Password needs to have atleast 8 characters and contain both letters and numbers.",
      });
      return;
    }

    if (this.state.new) {
      registerUser({
        email: email,
        password: password,
        first_name: fname,
        last_name: lname,
      }).then((result) => {
        this.account = result.data;

        window.ga("send", "event", {
          eventCategory: "invite_collaborator",
          eventAction: "successful",
          eventLabel: this.account.mail,
        });
        const uiid = this.getInstanceUserId(this.account);
        const name = `${fname} ${lname}`;
        this.props.firebase.createSharedProject(uiid, email, name, projectId, {
          author: authorId,
          name: projectName,
        });
        window.location.href = `https://learning.bogn.com/r/app/studio?jwt=${
          this.account.jwt
        }`;
      });
    } else {
      login({ username: email, password: password }).then((result) => {
        this.account = result.data;
        const uiid = this.getInstanceUserId(this.account);
        const name = `${this.account.first_name} ${this.account.last_name}`;
        this.props.firebase.createSharedProject(uiid, email, name, projectId, {
          author: authorId,
          name: projectName,
        });
        window.location.href = `https://learning.bogn.com/r/app/studio?jwt=${
          this.account.jwt
        }`;
      });
    }
  };

  getInstanceUserId(account) {
    let uiid = account.accounts[0].id;
    for (let i = 0; i < account.accounts.length; i++) {
      if (
        account.accounts[i].instance_name ===
        process.env.REACT_APP_GO1_INSTANCE_URL
      ) {
        uiid = account.accounts[i].id;
        break;
      }
    }
    return uiid;
  }

  handleBack = () => {
    this.setState({ step: this.state.step - 1 });
  };

  validatePassword = (str) => {
    if (
      str.length >= 8 &&
      str.search(/\d/) !== -1 &&
      str.search(/[a-zA-Z]/) !== -1
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {
      step,
      pageLoading,
      projectName,
      authorName,
      type,
      error,
    } = this.state;
    if (pageLoading) {
      return (
        <div className="container py-5">
          <Skeleton />
        </div>
      );
    }
    if (error) {
      return (
        <div
          className="container text-center"
          style={{ marginTop: "140px", marginBottom: "120px" }}
        >
          <img src="/images/icons8-error.png" />
          <h5 className="pt-5 col-lg-6 col-md-10 mx-auto">
            Hold on! It doesn’t look like this is a valid invitation link. You
            should contact your project owner for the correct link
          </h5>
        </div>
      );
    }

    let label;
    switch (type) {
      case "pms":
        label = "pioneer-migrator-settler map";
        break;
      case "pricing_corridors":
        label = "pricing corridor";
        break;
      default:
        label = "project";
    }
    return (
      <React.Fragment>
        <div className="container">
          <div
            className="col-lg-6 col-md-8 px-0 px-md-5 mx-auto text-center"
            style={{ marginTop: "100px", marginBottom: "80px" }}
          >
            <div
              className="col-md-10 mx-auto bg-light py-3 rounded shadow-lg"
              style={{ marginTop: "150px" }}
            >
              <div
                className="m-auto payment-image rounded-circle"
                style={{ backgroundImage: `url(images/icons8-share.svg)` }}
              />
              <h5 className="font-weight-normal text-primary">
                <b>{authorName}</b> wants to invite you to their {label}{" "}
                <b>{projectName}</b>
              </h5>
              <form
                onSubmit={this.handleSubmit}
                className="payment-form text-left pt-3"
              >
                {this.state.step >= EMAIL_STEP && (
                  <div className="form-group">
                    <Input
                      size="large"
                      placeholder="Email"
                      disabled={this.state.step >= 1}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    {this.validator.message(
                      "email",
                      this.state.email,
                      "required|email"
                    )}
                  </div>
                )}
                {this.state.step >= ACCOUNT_STEP && this.state.new && (
                  <div className="row mt-3 no-gutters">
                    <Divider
                      className="text-secondary mb-3 text-uppercase"
                      style={{ fontSize: "14px" }}
                    >
                      Complete Registration Details
                    </Divider>
                    <div className="col-md-6 form-group pr-md-2">
                      <Input
                        size="large"
                        placeholder="First Name"
                        disabled={this.state.step >= 2}
                        value={this.state.fname}
                        onChange={(e) =>
                          this.setState({ fname: e.target.value })
                        }
                      />
                      {this.validator.message(
                        "first name",
                        this.state.fname,
                        "required|alpha_space"
                      )}
                    </div>
                    <div className="col-md-6 form-group">
                      <Input
                        size="large"
                        placeholder="Last Name"
                        disabled={this.state.step >= 2}
                        value={this.state.lname}
                        onChange={(e) =>
                          this.setState({ lname: e.target.value })
                        }
                      />
                      {this.validator.message(
                        "last name",
                        this.state.lname,
                        "required|alpha_space"
                      )}
                    </div>
                  </div>
                )}
                {this.state.step >= ACCOUNT_STEP && (
                  <div className="form-group">
                    <Input.Password
                      size="large"
                      placeholder="Password"
                      disabled={this.state.step >= 2}
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    {this.validator.message(
                      "password",
                      this.state.password,
                      "required"
                    )}
                  </div>
                )}
                {this.state.step >= ACCOUNT_STEP && this.state.new && (
                  <div className="form-group">
                    <Input.Password
                      size="large"
                      placeholder="Confirm Password"
                      disabled={this.state.step >= 2}
                      value={this.state.confirm}
                      onChange={(e) =>
                        this.setState({ confirm: e.target.value })
                      }
                    />
                    {this.validator.message(
                      "confirm password",
                      this.state.confirm,
                      "required"
                    )}
                  </div>
                )}
                {this.state.step < ACCOUNT_STEP ? (
                  <Button
                    role="button"
                    className="btn-block"
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    role="button"
                    className="btn-block"
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                  >
                    Accept Invitation
                  </Button>
                )}
                {this.state.step !== EMAIL_STEP && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-block"
                    onClick={this.handleBack}
                  >
                    Back
                  </button>
                )}
              </form>
              {this.state.formError && (
                <Alert
                  className="mt-3 text-left"
                  message="Uh oh!"
                  description={this.state.formError}
                  type="error"
                  showIcon
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withFirebase(Invite);
