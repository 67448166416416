import React from 'react';

export default function NotFound() {
  return (
    <div class="text-center">
      <img class="img-fluid w-50" src="https://cdn.dribbble.com/users/1914815/screenshots/5377461/1hrm_ils2_4x.png" alt="404 Not Found" />
      <h4 class="text-primary">Page Not Found!</h4>
    </div>
  );
}
