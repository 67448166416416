import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import Prismic from 'prismic-javascript';
import PrismicReact from 'prismic-reactjs';
import { Select, Skeleton } from 'antd';
import ContactModal from '../components/ContactModal';

const { Option } = Select;

const ProductSlat = ({product}) => {
  const image = product ? product.data.card_image : null;
  return (
    <Link to={product ? `/product/${product.uid}` : '#'}>
      <div className="bg-white row m-0 mb-3 slat" style={{
        minHeight: '160px'
      }}>
        <div className="col-sm-3" style={{
          backgroundImage: `url(${image && image.url})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}></div>
        { product && (
          <div className="col-sm-9 p-3 px-4">
            <h5 className="mb-1 text-dark">
              {PrismicReact.RichText.asText(product.data.title)}
            </h5>
            <p>{PrismicReact.RichText.asText(product.data.subtitle)}</p>
            <p>{PrismicReact.RichText.asText(product.data.summary)}</p>
          </div>
        )}
        { !product && <Skeleton className="p-4" />}
      </div>
    </Link>
  )
};

export default class Consulting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchProducts(this.props);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getSingle('consulting').then((doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  fetchProducts(props, format) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        format ? Prismic.Predicates.any('my.product.format', [format]) : Prismic.Predicates.any('my.product.type', ['consulting']),
        { orderings : '[my.product.weight]' }
      ).then(response => {
        let products = {};
        response.results.forEach(item => {
          if (item.data.format) {
            if (!products[item.data.format]) {
              products[item.data.format] = [];
            }
            products[item.data.format].push(item);
          }
        });
        this.setState({ products });
      });
    }
    return null;
  }

  handleChange = (selected) => {
    this.fetchProducts(this.props, selected);
  }

  render() {
    const { prismicCtx } = this.props;
    const { doc, products, readMore } = this.state;

    if (!doc) {
      return <div className="container py-5"><Skeleton /></div>
    }

    return (
      <div id="consulting">
        <Helmet>
          <title>Blue Ocean Academy™</title>
          <meta name="description" content="The Official Blue Ocean Online Learning, Training, Coaching, Workshop, Webinar, Seminar, Executive Roundtable, Consulting & Leadership Transformation"></meta>
        </Helmet>
        <section className="video-bg-container">
          <div className="video-bg-overlay"></div>  
          <video className="video-bg" autoPlay muted loop>
            <source src="/images/Consulting_bg.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="container py-5 video-bg-title">
            <h2 className="font-weight-normal text-primary">{PrismicReact.RichText.asText(doc.data.title)}</h2>
            <div className="col-md-5 p-0">{PrismicReact.RichText.render(doc.data.overview, prismicCtx.linkResolver)}</div>
            <ContactModal className="btn-primary text-white float-left">Contact Us</ContactModal>
            {/* <Link to="/consulting-solutions" className="btn btn-outline-primary ml-2">Our Services</Link> */}
          </div>
        </section>
        <section className="bg-primary" style={{minHeight:'4px'}}></section>
        <section className="py-5">
          <div className="col-md-6 mx-auto">
            <h4 className="text-center my-4 text-primary">Market-Competing versus Market-Creating</h4>
            {PrismicReact.RichText.render(doc.data.thought_leadership, prismicCtx.linkResolver)}
            { readMore === true && (
              <div>
                {PrismicReact.RichText.render(doc.data.thought_leadership_read_more, prismicCtx.linkResolver)}
              </div>
            )}
            <a className="text-primary font-weight-semibold" style={{borderBottom: '1px solid'}} onClick={() => this.setState({readMore: !readMore ? true : false})}>{readMore ? 'Read less' : 'Read more' }</a>
          </div>
        </section>
        {/* <section className="container py-5">
          <div className="row">
            { doc.data.white_paper.map((paper, index) => {
              return (
                <a key={index} target="_blank" rel="noopener noreferrer" href={paper.white_paper_link.url} className="col-md-4">
                  <div
                    className="bg"
                    style={{
                      overflow: 'hidden',
                      height: '150px',
                    }}
                  >
                    <img src={paper.white_paper_image.url} alt={paper.white_paper_title} style={{transform: 'translateY(-20%)'}} />
                    <div className="overlay">
                      <h5 className="text-white py-5 text-center">{PrismicReact.RichText.asText(paper.white_paper_title)}</h5>
                    </div>
                  </div>
                </a>
              )
            })}
          </div>
        </section> */}
        {/* <section class="container py-5">
          <div className="row px-5">
            <div className="col-md-6 px-4">
              <h4 className="text-primary">Key Challenges</h4>
              {PrismicReact.RichText.render(doc.data.key_challenges, prismicCtx.linkResolver)}
            </div>
            <div className="col-md-6 px-4">
              <h4 className="text-primary">Consulting Methodology</h4>
              {PrismicReact.RichText.render(doc.data.consulting_methodology, prismicCtx.linkResolver)}
            </div>
            <div className="col-12 text-center mt-5">
              <Link to="/consulting-solutions" className="btn btn-primary">View Our Services</Link>
            </div>
          </div>
        </section> */}
        <section className="bg-light py-5">
          <div class="col-md-9 mx-auto">
            <div className="text-right mb-4">
              <Select defaultValue="" style={{ width: 200 }} onChange={this.handleChange}>
                <Option value="">All</Option>
                <Option value="Strategy Consulting">Strategy Consulting</Option>
                <Option value="Leadership Consulting">Leadership Consulting</Option>
              </Select>
            </div>
            { !products && (
              <ProductSlat loading={true} />
            )}
            { products && Object.keys(products).map(type => {
              return (
                <div key={type} className="mb-5">
                  <h5 class="text-capitalize text-dark mb-3">{type}</h5>
                  { products[type].map(product => {
                    return (<ProductSlat product={product} />)
                  })}
                </div>
              )
            })}
          </div>
        </section>
        <section className="container py-5">
          <h4 class="text-center text-primary">Some of our customers</h4>
          <div className="row">
            { doc.data.customer.map((customer, index) => {
              return (
                <div className="col-md-2-5 mb-4 px-5 my-auto">
                  <img className="img-fluid" src={customer.customer_logo.url} alt={PrismicReact.RichText.asText(customer.customer_name)} />
                </div>
              )
            })}
          </div>
        </section>
        <section className="bg-primary pb-5">
          <div className="container text-center py-4">
            <h3 className="text-white mt-4 mb-4">Explore the Blue Ocean Studio™ creative workspace!</h3>
            <a href="https://learn.blueoceanstrategy.com/blue-ocean-studio" className="btn btn-outline-primary">Learn more</a>
          </div>
        </section>
      </div>
    );
  }
}