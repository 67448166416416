import React from 'react';
import { Link } from 'react-router-dom';
import PrismicReact from 'prismic-reactjs';
import { Skeleton } from 'antd';

const handleClick = (e, item) => {
  if (!item || item.data.product_status === 'Inactive') {
    e.preventDefault();
  }
}

const ProductCard = (props) => {
  const inactive = !props.item || props.item.data.product_status === 'Inactive';
  const uid = props.item && props.item.uid;
  const image = props.item && props.item.data.card_image;
  return (
    <Link className={`p-0 product-card ${props.className}`} to={`/product/${uid}`} disabled={inactive} onClick={e => handleClick(e, props.item) }>
      <div className="d-none d-sm-block product-image" style={{backgroundImage: `url(${image && image.url})` }}>
        { !inactive && <button className="btn btn-outline-light">Learn More</button> }
        { inactive && !props.skeleton && <button disabled className="btn btn-dark">Coming Soon</button> }
      </div>
      { props.item && (
        <div className="px-3 product-body">
          <h5 className={`mt-3 mb-1 font-weight-bold ${!inactive ? 'text-primary' : ''}`}>{PrismicReact.RichText.asText(props.item.data.title)}</h5>
          <h6 className={!inactive ? 'text-primary' : ''}>{PrismicReact.RichText.asText(props.item.data.subtitle)}</h6>
          <p className="font-weight-light">{props.item.data.summary && PrismicReact.RichText.asText(props.item.data.summary)}</p>
        </div>
      )}
      { props.skeleton && (
        <div className="px-3 product-body">
          <Skeleton />
        </div>
      )}
    </Link>
  );
}

export default ProductCard;