import axios from 'axios';
import qs from 'qs';

const apiUrl = 'https://us-central1-studio-46bb4.cloudfunctions.net/api/stripe';

export const getCoupon = (couponCode) => {
  return axios.get(`${apiUrl}/coupon/${couponCode}`);
};

export const getSubscription = (jwt) => {
  return axios.get(`${apiUrl}/subscription`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });
};

export const createSubscription = (jwt, token, description, product, coupon, renewal) => {
  var body = {
    token,
    description,
    product,
    coupon,
    renewal,
  };
  return axios.post(`${apiUrl}/subscription`, qs.stringify(body), {
    headers: { Authorization: `Bearer ${jwt}` }
  });
};

export const createPayment = (jwt, token, description, product) => {
  var body = {
    token,
    description,
    product,
  };
  return axios.post(`${apiUrl}/payment`, qs.stringify(body), {
    headers: { Authorization: `Bearer ${jwt}` }
  });
};