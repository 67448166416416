import React from 'react';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import { Skeleton } from 'antd';

// Declare your component
export default class Resource extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      doc: null,
      notFound: false,
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentWillReceiveProps(nextProps){
    if (this.props.prismicCtx !== nextProps.prismicCtx ||
      nextProps.location.pathname !== this.props.location.pathname) {
      this.fetchPage(nextProps);
    }
  }

  fetchPage(props) {
    window.scrollTo(0, 0);
    this.setState({ doc: null });
    
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('resource', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
          document.title = `${PrismicReact.RichText.asText(doc.data.resource_title)} | Blue Ocean Academy`;
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      return (
        <div class="container py-5">
          <h2>{PrismicReact.RichText.asText(this.state.doc.data.resource_title)}</h2>
          {PrismicReact.RichText.render(this.state.doc.data.resource_body, this.props.prismicCtx.linkResolver)}
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <div class="container py-5"><Skeleton /></div>;
  }
}