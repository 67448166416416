export const countries = () => {
  return [
    {
      label: 'Australia',
      flag: 'australia.png',
      top: '79.9%',
      left: '83.9%'
    },
    {
      label: 'Brazil',
      flag: 'brazil.png',
      top: '70.6%',
      left: '35.2%',
    },
    {
      label: 'Canada',
      flag: 'canada.png',
      top: '37.4%',
      left: '27.7%'
    },
    {
      label: 'China',
      flag: 'china.png',
      top: '45.2%',
      left: '75.4%'
    },
    {
      label: 'Columbia',
      flag: 'columbia.png',
      top: '59.6%',
      left: '28.6%',
    },
    {
      label: 'France',
      flag: 'france.png',
      top: '36.5%',
      left: '47.7%',
    },
    {
      label: 'Germany',
      flag: 'germany.png',
      top: '33.5%',
      left: '50.2%',
    },
    {
      label: 'India',
      flag: 'india.png',
      top: '47.9%',
      left: '66.2%',
    },
    {
      label: 'Italy',
      flag: 'italy.png',
      top: '40.3%',
      left: '50.4%',
    },
    {
      label: 'Japan',
      flag: 'japan.png',
      top: '43.7%',
      left: '82%'
    },
    {
      label: 'Malaysia',
      flag: 'malaysia.png',
      top: '60.6%',
      left: '72.3%'
    },
    {
      label: 'Russia',
      flag: 'russia.png',
      top: '30.3%',
      left: '56.7%',
    },
    {
      label: 'Singapore',
      flag: 'singapore.png',
      top: '61.7%',
      left: '72.9%'
    },
    {
      label: 'South Africa',
      flag: 'south-africa.png',
      top: '75.8%',
      left: '53.7%',
    },
    {
      label: 'South Korea',
      flag: 'south-korea.png',
      top: '43.2%',
      left: '78.7%'
    },
    {
      label: 'Spain',
      flag: 'spain.png',
      top: '41%',
      left: '46%'
    },
    {
      label: 'Sri Lanka',
      flag: 'sri-lanka.png',
      top: '58.7%',
      left: '67%'
    },
    {
      label: 'Switzerland',
      flag: 'switzerland.png',
      top: '37.1%',
      left: '49.1%',
    },
    {
      label: 'UAE',
      flag: 'united-arab-emirates.png',
      top: '49.8%',
      left: '60.6%',
    },
    {
      label: 'Ukraine',
      flag: 'ukraine.png',
      top: '35%',
      left: '54.5%',
    },
    {
      label: 'United Kingdom',
      flag: 'united-kingdom.png',
      top: '34%',
      left: '46.6%',
    },
    {
      label: 'United States',
      flag: 'united-states.png',
      top: '42%',
      left: '27.3%'
    },
    {
      label: 'Vietnam',
      flag: 'vietnam.png',
      top: '51.7%',
      left: '73.2%'
    },
  ];
}