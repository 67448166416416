import React from 'react';
import { notification, Icon, Button } from 'antd';
import { forgotPassword } from '../services/user';

export default class ForgotPassword extends React.Component {
  
  state = {
    loading: false,
    iconLoading: false,
  }

  handleSubmit = (e) => {
    this.setState({ loading: true });
    forgotPassword(this.email.value)
      .then(
        (result) => {
          this.email.value = '';
          this.setState({ loading: false })
          notification.open({
            message: 'Email Sent',
            description: 'An email has been sent with a link to reset your password',
            icon: <Icon type="check-circle" style={{ color: '#33b73f' }} />,
            placement: 'bottomLeft',
          });
        }
      ).catch(err => {
        this.setState({ loading: false })
        notification.open({
          message: 'Error',
          description: err.message,
          icon: <Icon type="close-circle" style={{ color: '#f15757' }} />,
          placement: 'bottomLeft',
        });
      });
    e.preventDefault();
  }

  render() {
    return (
      <React.Fragment>
        <div class="container">
          <div class="col-lg-8 col-md-10 mx-auto text-center" style={{marginTop: 70}}>
            <h1 class="font-weight-normal text-primary p-lg-4 text-uppercase">Blue Ocean Studio™</h1>
            <p class="lead font-weight-light text-primary px-5">Learn and apply the concepts of Blue Ocean Shift and seize new opportunities with confidence.</p>
            <form class="col-md-8 m-auto pt-4" onSubmit={this.handleSubmit}>
              <div class="form-group">
                <input type="email" class="form-control" name="email" placeholder="Email" ref={(email) => this.email = email} />
              </div>
              <Button loading={this.state.loading} onClick={this.handleSubmit} type="primary">Reset Password</Button>
            </form>
            <div class="pt-3 message text-danger"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}