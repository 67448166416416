import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { login } from "../services/user";
import { Button } from "antd";

export default class Login extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    login({
      username: this.username.value,
      password: this.password.value,
    })
      .then((result) => {
        console.log(result);
        window.location.href = `https://${
          process.env.REACT_APP_GO1_INSTANCE_DOMAIN
        }/r/app/studio?jwt=${result.data.jwt}`;
      })
      .catch((err) => {
        this.setState({ loading: false, error: err.response.data.message });
        console.error(err);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Login | Blue Ocean Academy</title>
        </Helmet>
        <div class="container">
          <div
            class="col-lg-8 col-md-10 mx-auto text-center"
            style={{ marginTop: 70 }}
          >
            <h1 class="font-weight-normal text-primary p-lg-4 text-uppercase">
              Blue Ocean Studio™
            </h1>
            <p class="lead font-weight-light text-primary px-5">
              Learn and apply the concepts of Blue Ocean Strategy & Blue Ocean
              Shift to seize new growth opportunities.
            </p>
            <form class="col-md-8 m-auto pt-4" onSubmit={this.handleSubmit}>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Email"
                  ref={(username) => (this.username = username)}
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  placeholder="Password"
                  ref={(password) => (this.password = password)}
                />
                {this.state.error && (
                  <div class="pt-3 message text-danger">{this.state.error}</div>
                )}
              </div>
              <Button
                htmlType="submit"
                type="primary"
                loading={this.state.loading}
                onClick={this.handleSubmit}
              >
                Login
              </Button>
              <small class="d-block pt-3 text-uppercase">
                <Link to="/forgot-password">Forgot password?</Link>
              </small>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
