import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';

// const config = {
//   apiKey: "AIzaSyA0_jZmJa5twa8e8RwMsLS72f4CyWD4yNM",
//   authDomain: "bogn-react-website.firebaseapp.com",
//   databaseURL: "https://bogn-react-website.firebaseio.com",
//   projectId: "bogn-react-website",
//   storageBucket: "bogn-react-website.appspot.com",
//   messagingSenderId: "197517760225"
// };

const studioConfig = {
  apiKey: "AIzaSyCA5h_2WoGNL75eIX-ootPhy0gMj1ZqywQ",
  authDomain: "studio-46bb4.firebaseapp.com",
  databaseURL: "https://studio-46bb4.firebaseio.com",
  projectId: "studio-46bb4",
};

class Firebase {
  constructor() {
    app.initializeApp(studioConfig);
    this.db = app.firestore();
    this.realtime = app.database();
  }

  enquiries = () => this.db.collection('enquire');

  createSubscription = (uid, projectId, expiry, status, stripeId) => {
    const ref = this.realtime.ref(`users/${uid}/subscription`);
    const data = {
      course_project_id: projectId,
      expiry: expiry,
      status: status
    };
    if (stripeId) {
      data.stripe_id = stripeId;
    }
    return ref.set(data);
  };

  getSubscription = (uid, callback) => {
    const ref = this.realtime.ref(`users/${uid}/subscription`);
    return ref.once('value', callback);
  };

  createCourseProject = (uid, account, projectId) => {
    const ref = this.realtime.ref(`projects/${projectId}`);
    const data = {
      name: 'Blue Ocean Studio™ Companion Course',
      author: uid,
      type: 'course',
      authorDetail: {
        name: account.first_name + ' ' + account.last_name,
        avatar: ''
      }
    };
    return ref.set(data);
  };

  getSharedProject = (projectId, type = 'projects') => {
    const ref = this.realtime.ref(`${type}/${projectId}`);
    return ref.once('value');
  }

  createSharedProject = (userId, mail, name, projectId, project, type = 'projects') => {
    const projectRef = this.realtime.ref(`${type}/${projectId}`);
    projectRef.once('value', (data) => {
      const projectValue = data.val();
      console.log(projectValue);
      let collaborators = projectValue.collaborators && Object.values(projectValue.collaborators);
      if (!collaborators) {
        collaborators = [];
      }
      console.log(JSON.stringify(collaborators));
      collaborators.push({
        id: userId,
        mail,
        name,
      });
      projectRef.child('collaborators').set(collaborators);
    });
    return this.realtime.ref(`users/${userId}/shared_${type}`).push({
      author: project.author,
      id: projectId,
      name: project.name,
    });
  }
}

export default Firebase;