import axios from 'axios';

export const createEnrolment = (account, courseId, customerId) => {
  return axios.post(`https://api.go1.co/enrolment/${process.env.REACT_APP_GO1_INSTANCE_ID}/${courseId}/enrolment?reEnrol=0`, {
    notify: false,
    paymentMethod: 'stripe',
    paymentOptions: {
      customer: customerId
    }
  },{
    headers: {
      'Authorization': `Bearer ${account.jwt}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}